import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { setFilter } from "home/actions"

const ChartFilter = ({ name, filters, options, dispatch }) => (
  <div className="chart-filter">
    <select onChange={e => dispatch(setFilter({ [name]: e.target.value }))} value={filters[name]}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
)

ChartFilter.propTypes = {
  name: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  filters: PropTypes.object,
  options: PropTypes.array
}

const mapStateToProps = state => {
  return { filters: state.filters }
}

export default connect(mapStateToProps)(ChartFilter)
