import ReactHighcharts from "react-highcharts"

import { NoDataToDisplay } from "react-highcharts-no-data-to-display"

NoDataToDisplay(ReactHighcharts.Highcharts)

export function configureHighcharts () {
  ReactHighcharts.Highcharts.setOptions({
    chart: {
      style: {
        fontFamily: "ubunturegular",
        fontSize: "12px"
      }
    },
    lang: {
      loading: "Aguarde...",
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ],
      weekdays: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado"
      ],
      shortMonths: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ],
      exportButtonTitle: "Exportar",
      printButtonTitle: "Imprimir",
      rangeSelectorFrom: "De",
      rangeSelectorTo: "Até",
      rangeSelectorZoom: "Período",
      downloadPNG: "Download imagem PNG",
      downloadJPEG: "Download imagem JPEG",
      downloadPDF: "Download documento PDF",
      downloadSVG: "Download imagem SVG",
      resetZoom: "Reset",
      resetZoomTitle: "Reset",
      thousandsSep: ".",
      decimalPoint: ",",
      noData: "Este gráfico não possui dados para serem apresentados!"
    },
    noData: {
      position: {
        x: 0,
        y: 0,
        align: "center",
        verticalAlign: "middle"
      }
    }
  })
}
