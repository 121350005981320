import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { EnemExamPerformanceByAreaPresentation } from "home/components/enem_exam"

class EnemExamPerformanceByAreaContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      chartPatch: []
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("enem_exam/performance_by_area/chart", filters, response =>
      this.setChartConfigFromResponse(response)
    )
  }

  groupBy (list, keyGetter) {
    const map = new Map()

    list.forEach(item => {
      const key = keyGetter(item)
      if (!map.has(key)) {
        map.set(key, [item])
      } else {
        map.get(key).push(item)
      }
    })

    return map
  }

  setChartConfigFromResponse ({ data }) {
    const slugs = {
      "Ciências da Natureza": "(CN)",
      "Ciências Humanas": "(CH)",
      "Linguagens e Códigos": "(LC)",
      Matemática: "(MA)",
      Redação: "(RED)"
    }

    const areas = Array.from(new Set(data.map(d => d.knowledge_area)))
    const xAxis = Array.from(new Set(data.map(d => `${d.knowledge_area} ${slugs[d.knowledge_area]}`)))

    const dataBySchoolYears = this.groupBy(data, d => d.school_year)
    const series = Array.from(dataBySchoolYears.keys()).map(schoolYear => {
      const results = dataBySchoolYears.get(schoolYear)

      return {
        name: schoolYear,
        color: results[0].color,
        data: areas.map(x => {
          const result = results.find(r => r.knowledge_area === x)
          return result ? [result.grade] : [null]
        }),
        school_year: results[0].school_year_index
      }
    })
    this.setState({ chartPatch: { xAxis: { categories: xAxis }, series } })
  }

  render () {
    return <EnemExamPerformanceByAreaPresentation patch={this.state.chartPatch} />
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolClass: state.filters.schoolClass,
      schoolId: state.filters.schoolId,
      "enemExams/performanceByArea": state.filters["enemExams/performanceByArea"]
    }
  }
}

export default connect(mapStateToProps)(EnemExamPerformanceByAreaContainer)
