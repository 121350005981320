import React from "react"
import Product from "home/components/product"
import { MixpanelTrack } from "home/mixpanel"

import { CaptionedDivWithFilter } from "home/components/layout"
import {
  EnemStudentPerformanceTableContainer,
  AbilityByAreaChartContainer,
  CompetenceByAreaChartContainer,
  EnemAnswerResultTableContainer
} from "home/components/enem_proficiency_analysis"

import { EnemStudentPerformanceFilter, EnemAnswerResultFilter, KnowledgeAreaFilter } from "home/components/filters"

const EnemProficiencyAnalysisProduct = () => {
  MixpanelTrack("Acessou Produto Análise de Proeficiências ENEM")

  return (
    <Product caption="Análises de Proficiência (ENEM)">
      <div className="grid grid-double">
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={KnowledgeAreaFilter}
            filterKey="enemAnalysis/competence/knowledgeArea"
            filterCategory="ENEM"
            caption="Competências"
          >
            <CompetenceByAreaChartContainer />
          </CaptionedDivWithFilter>
          <div className="product-element-footer" />
        </div>
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={KnowledgeAreaFilter}
            filterKey="enemAnalysis/ability/knowledgeArea"
            filterCategory="ENEM"
            caption="Habilidades"
          >
            <AbilityByAreaChartContainer />
          </CaptionedDivWithFilter>
          <div className="product-element-footer" />
        </div>
      </div>
      <div>
        <div className="product-element">
          <CaptionedDivWithFilter
            filterType={EnemStudentPerformanceFilter}
            filterKey="enemAnalysis/studentPerformance/examId"
            filterCategory="ENEM"
            caption="Proficiência por aluno"
          >
            <EnemStudentPerformanceTableContainer />
          </CaptionedDivWithFilter>
        </div>
      </div>
      <div>
        <div className="product-element">
          <CaptionedDivWithFilter
            filterType={EnemAnswerResultFilter}
            filterKey="enemAnalysis/answerResult/examId"
            filterCategory="ENEM"
            caption="Acertos / Erros por questão"
          >
            <EnemAnswerResultTableContainer />
          </CaptionedDivWithFilter>
        </div>
      </div>
    </Product>
  )
}

export default EnemProficiencyAnalysisProduct
