import React from "react"
import { Cell } from "fixed-data-table-2"

export default class LinkCell extends React.PureComponent {
    render () {
      const { rowIndex, columnKey, data, ...props } = this.props
      const link = data[rowIndex][columnKey]
      return (
        <Cell {...props}>
          <a href={link} target="_blank" rel="noreferrer">{link}</a>
        </Cell>
      )
    }
  }