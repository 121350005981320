import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { DailyMissionAnsweredBadgePresentation } from "home/components/profile"

class DailyMissionAnsweredBadgeContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      available: 0,
      answered: 0
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters.schoolId !== this.props.filters.schoolId) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("profile/daily_mission_answered", filters, response => this.setBadgeConfigFromResponse(response))
  }

  setBadgeConfigFromResponse (response) {
    this.setState({
      answered: response.data.answered,
      available: response.data.available
    })
  }

  render () {
    return <DailyMissionAnsweredBadgePresentation available={this.state.available} answered={this.state.answered} />
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(DailyMissionAnsweredBadgeContainer)
