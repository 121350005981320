import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { hasFiltersApplied } from "home/components/filters/filter_helper"

class TableWrapper extends PureComponent {
  render () {
    if (this.props.tableData.data == null) {
      return <div>Aguardando Dados</div>
    } else {
      const PresenterComponent = this.props.presenter

      return (
        <PresenterComponent
          tableData={this.props.tableData}
          resource={this.props.resource}
          hasFilters={hasFiltersApplied(this.props.filters)}
        />
      )
    }
  }
}

TableWrapper.propTypes = {
  tableData: PropTypes.object.isRequired,
  presenter: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired
}

export default TableWrapper
