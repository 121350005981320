import React, { Component } from "react"
import ReactHighcharts from "react-highcharts"

const baseChart = {
  chart: {
    type: "column"
  },
  title: {
    text: null
  },
  xAxis: {
    type: "category"
  },

  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0
    }
  },
  tooltip: {
    headerFormat:
      "<div style='padding: 10px;'><div style='font-weight: bold;text-align:center;margin-bottom:10px;'>{point.key}</div><table>",
    pointFormat:
      "<tr><td style='color:{series.color};padding:0;text-align: center;font-weight: bold;'>{series.name}: &nbsp; </td>" +
      "<td style='text-align: center;margin-bottom:5px;'>{point.y:1f}</td></tr>",
    footerFormat: "</table>",
    useHTML: true,
    shared: true
  },
  yAxis: {
    title: {
      text: "Nota na escala SAEB"
    }
  }
}

class SaebExamPerformanceByAreaPresentation extends Component {
  render () {
    const mergedChartConfig = Object.assign({}, baseChart, this.props.patch)
    return <ReactHighcharts config={mergedChartConfig} />
  }
}

export default SaebExamPerformanceByAreaPresentation
