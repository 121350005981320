import React from "react"

const SchoolInfoPresentation = ({
  schoolName,
  schoolInep,
  schoolMindlabCustomerSuccess,
  schoolCity,
  schoolState,
  schoolContactPersonPhone,
  schoolContactPersonName,
  schoolContactPersonJobTitle,
  schoolContactPersonEmail,
  schoolMindlabSalesman,
  schoolMindlabTrainer,
  schoolHtmlInstructions,
}) => (
  <div className="school-profile-badge">
    <span className="school-profile-badge-icon" />
    <div className="school-profile-badge-label">{schoolName}</div>
    <span className="spacer margin-top-10 margin-bottom-10" />
    <div className="school-profile-badge-text">
      <div>
        <span className="bold">Código INEP:</span> {schoolInep}
      </div>
      <div>
        <span className="bold">Estado:</span> {schoolState}
      </div>
      <div>
        <span className="bold">Cidade:</span> {schoolCity}
      </div>
      <div>
        <span className="bold">Responsável Escola:</span> {schoolContactPersonName}{" "}
        {schoolContactPersonJobTitle && "(" + schoolContactPersonJobTitle + ")"}
      </div>
      <div>
        <span className="bold">E-mail Responsável:</span> {schoolContactPersonEmail}
      </div>
      <div>
        <span className="bold">Telefone Responsável:</span> {schoolContactPersonPhone}
      </div>
      <span className="spacer margin-top-10 margin-bottom-10" />
    </div>
    <div className="school-profile-badge-label">
      <span>Mind Lab - Programa MenteInovadora </span>
    </div>
    <div className="school-profile-badge-text">
      <div>
        <span className="bold">Contato Principal (Responsável Pedagógico):</span> {schoolMindlabTrainer}
      </div>
      <div>
        <span className="bold">Canais de Atendimento:</span>
        <a href="http://mlbr.com.br/whats" target="_blank" rel="noreferrer">
          {" "}
          Whatsapp
        </a>
        ,
        <a href="http://mlbr.com.br/meajuda" target="_blank" rel="noreferrer">
          {" "}
          Chat Online
        </a>
        ,
        <a href="mailto:ajuda@mindlab.com.br" target="_blank" rel="noreferrer">
          {" "}
          E-mail
        </a>
      </div>
      <div>
        <span className="bold">Gerente de Operações:</span> {schoolMindlabCustomerSuccess}
      </div>
      <div>
        <span className="bold">Responsável Comercial:</span> {schoolMindlabSalesman}
      </div>
      <span className="spacer margin-top-10 margin-bottom-10" />
      <div>
        <span className="bold">Instruções: </span>
        <span dangerouslySetInnerHTML={{ __html: schoolHtmlInstructions }} />
      </div>
    </div>
  </div>
)

export default SchoolInfoPresentation
