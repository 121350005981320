import React, { Component } from "react"
import ReactHighcharts from "react-highcharts"

const baseChart = {
  chart: {
    type: "column"
  },
  title: {
    text: null
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0
    }
  },
  tooltip: {
    headerFormat: "<span style='font-weight: bold;'>{point.key}</span><table>",
    pointFormat:
      "<tr><td style='color:{series.color};padding:0;text-align: right;'>{series.name}: &nbsp; </td>" +
      "<td style='padding:0'><b>{point.y:.0f}</b></td></tr>",
    footerFormat: "</table>",
    shared: true,
    useHTML: true
  },
  yAxis: {
    title: {
      text: "Nota na escala ENEM"
    }
  }
}

class EnemExamPerformanceByAreaPresentation extends Component {
  render () {
    const mergedChartConfig = Object.assign({}, baseChart, this.props.patch)
    return <ReactHighcharts config={mergedChartConfig} />
  }
}

export default EnemExamPerformanceByAreaPresentation
