import React, { Component } from "react"
import PropTypes from "prop-types"
import { requestResource } from "home/api_request"
import { ChartFilter } from "home/components/filters"
import { setFilter } from "home/actions"
import { connect } from "react-redux"

class KnowledgeAreaFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      options: []
    }
  }

  shouldComponentUpdate (nextState) {
    return this.state.options != nextState.options
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
      this.updateData()
    }
  }

  updateData () {
    const { filters, filterCategory: category } = this.props

    requestResource("knowledge_areas", { category: category, ...filters }, response =>
      this.setOptionsFromResponse(response.data)
    )
  }

  setOptionsFromResponse (data) {
    this.setState({
      options: this.parseAreas(data)
    })
  }

  parseAreas (data) {
    const { dispatch, filterKey } = this.props

    const options = data.map(knowledge_area => {
      return {
        value: knowledge_area,
        label: knowledge_area
      }
    })

    if (options.length > 0) {
      dispatch(setFilter({ [filterKey]: options[0].value }))
    }

    return options
  }

  render () {
    return <ChartFilter name={this.props.filterKey} options={this.state.options} />
  }
}

KnowledgeAreaFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterCategory: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolClass: state.filters.schoolClass,
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(KnowledgeAreaFilter)
