import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { setFilter } from "home/actions"

const SchoolUserFilterDropdown = ({ users, dispatch }) => {
  const handleClick = event => {
    const { innerText: userName, value: userId } = event.target
    document.getElementById("school-user-filter-input").value = userName
    dispatch(setFilter({ userId: userId, userName: userName }))
  }

  return (
    <div className="school-user-dropdown-content" id="school-user-dropdown-content">
      <ul className="school-user-filter-dropdown">
        {users.map(user => (
          <li key={user.user_id} value={user.user_id} onMouseDown={handleClick}>
            {user.user_name}{" "}
          </li>
        ))}
      </ul>
    </div>
  )
}

SchoolUserFilterDropdown.propTypes = {
  users: PropTypes.array
}

export default connect()(SchoolUserFilterDropdown)
