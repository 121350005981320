import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { SingleEssayTablePresentation } from "home/components/single_essay"
import TableWrapper from "home/components/table_wrapper"

const RESOURCE = "single_essay/table"

class SingleEssayTableContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tableData: {
        data: null,
        columns: null
      }
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource(RESOURCE, filters, response => this.setChartConfigFromResponse(response))
  }

  setChartConfigFromResponse (response) {
    this.setState({
      tableData: response.data
    })
  }

  render () {
    return (
      <TableWrapper
        presenter={SingleEssayTablePresentation}
        tableData={this.state.tableData}
        resource={RESOURCE}
        filters={this.props.filters}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolGroup: state.filters.schoolGroup,
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(SingleEssayTableContainer)
