import React, { Component } from "react"
import PropTypes from "prop-types"

export default class CaptionedDiv extends Component {
  render () {
    return (
      <div className="product-element">
        <div className="product-element-title">
          <h2>{this.props.caption}</h2>
        </div>
        {React.Children.toArray(this.props.children).map((children, index) => (
          <div key={index}>{children}</div>
        ))}
      </div>
    )
  }
}

CaptionedDiv.propTypes = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ])
}
