import axios from "axios"
import { logout } from "home/actions"
import { apiEndpoint } from "config"
import { getAuthToken } from "auth"

class API {
  constructor (baseURL) {
    this.axios = axios.create({ baseURL: baseURL })
  }

  configDefaults (appStore) {
    this.axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error.response.status === 401) {
          appStore.dispatch(logout())
        }
        if (error.response.status === 403) {
          appStore.dispatch(logout())
        }
        return Promise.reject(error)
      }
    )
  }

  defaultHeaders () {
    const authToken = getAuthToken()
    return { authorization: `Bearer: ${authToken}` }
  }

  get (path, successCallback, failureCallback) {
    return this.axios
      .get(path, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  post (path, body, successCallback, failureCallback) {
    return this.axios
      .post(path, body, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  put (path, body, successCallback, failureCallback) {
    return this.axios
      .put(path, body, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  putWithOptions (path, body, options, successCallback, failureCallback) {
    return this.axios
      .put(path, body, { ...options, headers: { ...this.defaultHeaders(), ...options.headers } })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  patch (path, body, successCallback, failureCallback) {
    return this.axios
      .patch(path, body, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }

  delete (path, successCallback, failureCallback) {
    return this.axios
      .delete(path, { headers: this.defaultHeaders() })
      .then(response => {
        successCallback && successCallback(response)
      })
      .catch(e => {
        failureCallback && failureCallback(e)
      })
  }
}

const AquariusAPI = new API(`${apiEndpoint}/api`)

export default AquariusAPI
