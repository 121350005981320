import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import APIHelper from "home/api_helper"
import API from "api"
import { SchoolUserFilterDropdown } from "home/components/filters"
import { setFilter } from "home/actions"

class SchoolUserFilter extends Component {
  constructor (props) {
    super(props)
    this.state = { users: [] }
    this.onBlur = this.onBlur.bind(this)
  }

  resetFilter () {
    const { dispatch } = this.props
    dispatch(setFilter({ userId: "ALL", userName: "" }))
  }

  onKeyPress (event) {
    const typed = event.target.value

    if (typed.length >= 3) {
      let url = APIHelper.buildURL("school_users", this.props.filters)

      if (url.substring(url.length - 1) !== "?") {
        url = url + "&"
      }
      url = url + "query=" + typed

      API.get(url, response => {
        this.setState({ users: response.data })
      })
    } else {
      this.setState({ users: [] })

      if (typed.length === 0) {
        this.resetFilter()
      }
    }
  }

  onBlur () {
    document.getElementById("school-user-dropdown-content").style.display = "none"
  }

  onFocus () {
    document.getElementById("school-user-dropdown-content").style.display = "block"
  }

  render () {
    return (
      <div className="filter">
        <label>Aluno</label>
        <input
          className="input-filter"
          id="school-user-filter-input"
          onKeyUp={event => this.onKeyPress(event)}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          defaultValue={this.props.filters.userName}
        />
        <span className="input-filter-icon" />
        <SchoolUserFilterDropdown users={this.state.users} />
      </div>
    )
  }
}

SchoolUserFilter.propTypes = {
  filters: PropTypes.object
}

const mapStateToProps = state => {
  return {
    filters: state.filters
  }
}

export default connect(mapStateToProps)(SchoolUserFilter)
