import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router"
import { logout } from "home/actions"
import { MixpanelTrack, MixpanelReset } from "home/mixpanel"
import API from "api"

class Logout extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fireRedirect: false }
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout () {
    API.delete("/sessions", () => {
      MixpanelTrack("Efetuou logout")
      MixpanelReset()
      this.props.dispatch(logout())
    }).then(this.setState({ fireRedirect: true }))
  }

  render () {
    const { fireRedirect } = this.state

    if (fireRedirect) {
      return <Redirect to="/entrar" />
    }

    return (
      <span className="btn-logout" onClick={this.handleLogout}>
        sair
      </span>
    )
  }
}

export default connect()(Logout)
