import React from "react"
import { Logo } from "home/components/layout"
import { FilterBar } from "home/components/filters"

const Header = ({ location }) => (
  <div id="header" className="header">
    <Logo />
    <FilterBar location={location} />
  </div>
)

export default Header
