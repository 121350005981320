import React from "react"

const DailyMissionAnsweredBadgePresentation = ({ answered, available }) => (
  <div className="daily-mission-answered-badge text-center">
    <div className="inline-block-element">
      <span className="daily-mission-answered-badge-icon" />
    </div>
    <div className="inline-block-element text-left">
      <span className="badge-container-count">{answered}</span>
      <span className="badge-container-label">questões de missão<br /> diária respondidas</span>
      <span className="badge-container-small-label">de {available} disponibilizadas</span>
    </div>
  </div>
)
export default DailyMissionAnsweredBadgePresentation
