import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { SaebExamPerformanceByAreaPresentation } from "home/components/saeb_exam"

class SaebExamPerformanceByAreaContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      chartPatch: []
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
      this.updateData()
    }
  }

  updateData () {
    const filters = Object.assign({}, this.props.filters, {
      areaSlug: this.props.areaSlug
    })

    requestResource("saeb_exam/performance_by_area", filters, response => this.setChartConfigFromResponse(response))
  }

  setChartConfigFromResponse (response) {
    this.setState({
      chartPatch: response.data.patch
    })
  }

  render () {
    if (this.state.chartPatch === null) {
      return <div>Aguardando dados</div>
    } else {
      return <SaebExamPerformanceByAreaPresentation patch={this.state.chartPatch} />
    }
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolClass: state.filters.schoolClass,
      schoolId: state.filters.schoolId,
      "saebExams/performanceByArea": state.filters["saebExams/performanceByArea"]
    }
  }
}

export default connect(mapStateToProps)(SaebExamPerformanceByAreaContainer)
