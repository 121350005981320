import MockedPDF from "home/components/data_table/Escola_TESTE_Ano_X_Aluno_FULANO.pdf"

export const mockData = {
  data: {
    columns: [
        { key: "user_name", label: "Nome" },
        { key: "user_username", label: "Nome de Usuário" },
        { key: "school_class", label: "Turma" },
        { key: "school_year", label: "Ano" },
        { key: "sent_at", label: "Enviado em" },
        { key: "link_download", label: "Download" },
        { key: "evaluation", label: "Avaliação" },
        { key: "self_regulation", label: "Autorregulação" },
        { key: "problem_solving", label: "Resolução de Problemas" },
        { key: "team_work", label: "Trabalho em Equipe" },
        { key: "decision_making", label: "Tomada de Decisão" },
        { key: "resource_management", label: "Gerenciamento de Recursos" },
        { key: "self_knowledge", label: "Autoconhecimento" },
        { key: "grade", label: "Nota" },
        { key: "link_report_card", label: "Link do Boletim" },
    ],
    data: [
        {
            user_id: 97957,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest1",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: "avaliacao-socioemocional-aluno?id=6008569dc5d83c002ec45e0c",
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97958,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest2",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: "avaliacao-socioemocional-aluno?id=6008569dc5d83c002ec45e0c",
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97959,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest3",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: "avaliacao-socioemocional-aluno?id=6008569dc5d83c002ec45e0c",
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97900,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest4",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: "avaliacao-socioemocional-aluno?id=6008569dc5d83c002ec45e0c",
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97901,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest5",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: "avaliacao-socioemocional-aluno?id=6008569dc5d83c002ec45e0c",
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97902,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest6",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97903,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest7",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97857,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest8",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97997,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest9",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97107,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest10",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97117,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest11",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97127,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest12",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97137,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest13",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97147,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest14",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97157,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest15",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97167,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest16",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97177,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest17",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97187,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest18",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97197,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest19",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97207,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest20",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97217,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest21",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97227,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest22",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97237,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest23",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97247,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest24",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97257,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest25",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97267,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest26",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97277,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest27",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97287,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest28",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97297,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest29",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
        {
            user_id: 97307,
            user_name: "User Teste com fluxo de pagamento",
            user_status: "Cadastro concluído",
            user_username: "usertest30",
            school_class: "Matutino - A",
            school_year: "5º ano EFI",
            sent_at: "2021-01-21 13:00:00.00",
            evaluation: 6,
            self_regulation: 7.5,
            problem_solving: 5,
            team_work: 4,
            decision_making: 3,
            resource_management: 8.5,
            self_knowledge: 10,
            grade: 8.7,
            link_download: MockedPDF,
            link_report_card: "https://www.google.com",
        },
    ],
    rows_count: 30,
    }
}

export const mockChart = {
    data: {
        finalized_exams: 1,
        patch: {
            series: [ {
                data: [ { borderColor: "#FBA167", color: "#ffffff", name: "5º ano EFI", percentage: 50, y: 2 } ],
                dataLabels: { crop: false, format: "{point.y} disponíveis", overflow: "allow" },
                name: "Avaliações",
                showInLegend: false,
            },{
                data: [ { borderColor: "#FBA167", color: "#FBA167", name: "5º ano EFI", percentage: 50, y: 1 } ],
                dataLabels: { align: "right", format: "{point.y}", inside: true, x: 2 },
                name: "Avaliações Finalizadas",
                showInLegend: false
            }
        ] },
        total_exams: 2
    }
}

export const mockBarChartAbilities = {
    data: {
        patch: {
            series: [ {
                data: [ 
                    { borderColor: "#FBA167", color: "#ffffff", name: "Autorregulação", percentage: 50, y: 50 }, 
                    { borderColor: "#FBA167", color: "#ffffff", name: "Resolução de Problemas", percentage: 30, y: 30 }, 
                    { borderColor: "#FBA167", color: "#ffffff", name: "Trabalho em Equipe", percentage: 10, y: 10 }, 
                    { borderColor: "#FBA167", color: "#ffffff", name: "Tomada de Decisão", percentage: 30, y: 30 }, 
                    { borderColor: "#FBA167", color: "#ffffff", name: "Gerenciamento de Recursos", percentage: 70, y: 70 }, 
                    { borderColor: "#FBA167", color: "#ffffff", name: "Autoconhecimento", percentage: 80, y: 80 } 
                ],
                dataLabels: { crop: false, format: "{point.y}%", overflow: "allow" },
                name: "Avaliações",
                showInLegend: false,
            },{
                data: [
                    { borderColor: "#FBA167", color: "#FBA167", name: "Autorregulação", percentage: 50, y: 50 }, 
                    { borderColor: "#FBA167", color: "#FBA167", name: "Resolução de Problemas", percentage: 30, y: 30 }, 
                    { borderColor: "#FBA167", color: "#FBA167", name: "Trabalho em Equipe", percentage: 10, y: 10 }, 
                    { borderColor: "#FBA167", color: "#FBA167", name: "Tomada de Decisão", percentage: 30, y: 30 }, 
                    { borderColor: "#FBA167", color: "#FBA167", name: "Gerenciamento de Recursos", percentage: 70, y: 70 }, 
                    { borderColor: "#FBA167", color: "#FBA167", name: "Autoconhecimento", percentage: 80, y: 80 }  
                ],
                dataLabels: { align: "right", format: "{point.y}", inside: false, x: 2 },
                name: "Avaliações Finalizadas",
                showInLegend: false
            }
        ] },
    }
}