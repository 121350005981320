import React from "react"
import { connect } from "react-redux"
import { isAuthenticated } from "auth"
import { Filter } from "home/components/filters"

const SchoolFilter = ({ schools }) => {
  const options = schools.map(school => {
    return {
      value: school.id,
      label: school.name
    }
  })

  if (options.length > 1) {
    return <Filter caption="Escola" name="schoolId" options={options} />
  } else {
    return null
  }
}

const mapStateToProps = state => {
  const { currentUser } = state.auth

  return {
    schools: isAuthenticated() ? currentUser.schools : []
  }
}

export default connect(mapStateToProps)(SchoolFilter)
