import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

export default class Product extends Component {
  render () {
    return (
      <div>
        <div className="grid grid-center margin-bottom-20">
          <Link to="/" className="back-button" />
          <h1 className="main-title">{this.props.caption}</h1>
          <span className="spacer" />
        </div>
        {this.props.children}
      </div>
    )
  }
}

Product.propTypes = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ])
}
