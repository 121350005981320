import React from "react"
import {
  SchoolFilter,
  SchoolYearFilter,
  SchoolClassFilter,
  SchoolUserFilter,
  DateRangeFilter
} from "home/components/filters"

const FilterBar = ({ location }) => (
  <div id="header-filters" className="header-filters">
    <div className="grid-container">
      <div className="grid grid-gutters-paddding text-center">
        <div className="grid-cell">
          <SchoolFilter />
          <SchoolYearFilter />
          <SchoolClassFilter />
          <SchoolUserFilter />
          {location === "/missao-diaria" && <DateRangeFilter />}
        </div>
      </div>
    </div>
  </div>
)

export default FilterBar
