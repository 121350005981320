import React from "react"
import { connect } from "react-redux"
import { setFilter } from "home/actions"
import moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const DateRangeFilter = ({ filters, dispatch }) => {
  const startDate = moment(filters.dateFilterStartDate).toDate()
  const endDate = moment(filters.dateFilterEndDate).toDate()
  const startOfYear = moment()
    .startOf("year")
    .toDate()

  return (
    <span>
      <div className="filter">
        <span className="range-filter">
          <label>De: </label>
          <DatePicker
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={date => dispatch(setFilter({ dateFilterStartDate: moment(date).format("YYYY-MM-DD") }))}
            minDate={startOfYear}
            maxDate={endDate}
          />
        </span>
      </div>

      <div className="filter margin-right-0">
        <span className="range-filter">
          <label>Até: </label>
          <DatePicker
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            onChange={date => dispatch(setFilter({ dateFilterEndDate: moment(date).format("YYYY-MM-DD") }))}
            minDate={startDate}
            maxDate={moment().toDate()}
          />
        </span>
      </div>
    </span>
  )
}

const mapStateToProps = state => {
  return {
    filters: state.filters
  }
}

export default connect(mapStateToProps)(DateRangeFilter)
