import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"
import { initStore } from "home/store"
import { configureHighcharts } from "home/react_highcharts_helper"
import {
  ProfileProduct,
  DailyMissionProduct,
  EnemExamProduct,
  SaebExamProduct,
  BnccExamProduct,
  SaebProficiencyAnalysisProduct,
  EnemProficiencyAnalysisProduct,
  BnccProficiencyAnalysisProduct,
  SocioemotionalExamProduct
} from "home/components/products"
//import { SocioemotionalExamStudent } from "home/components/socioemotional"
import AuthenticatedRoute from "home/components/authenticated-route"
import ProductsListContainer from "home/components/products_list/products_list_container"
import SessionNew from "home/components/session/new"
import { MixpanelInitialize } from "home/mixpanel"
import API from "api"
import "../css/app.css"

const { store, persistor } = initStore()

API.configDefaults(store)
MixpanelInitialize()
configureHighcharts()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/entrar" component={SessionNew} />
          <AuthenticatedRoute exact path="/" component={ProductsListContainer} />
          <AuthenticatedRoute exact path="/perfil" component={ProfileProduct} />
          <AuthenticatedRoute exact path="/missao-diaria" component={DailyMissionProduct} />
          <AuthenticatedRoute exact path="/avaliacoes-enem" component={EnemExamProduct} />
          <AuthenticatedRoute exact path="/avaliacoes-saeb" component={SaebExamProduct} />
          <AuthenticatedRoute exact path="/avaliacoes-bncc" component={BnccExamProduct} />
          <AuthenticatedRoute exact path="/analises-de-proficiencia-saeb" component={SaebProficiencyAnalysisProduct} />
          <AuthenticatedRoute exact path="/analises-de-proficiencia-enem" component={EnemProficiencyAnalysisProduct} />
          <AuthenticatedRoute exact path="/analises-de-proficiencia-bncc" component={BnccProficiencyAnalysisProduct} />
          <AuthenticatedRoute exact path="/avaliacoes-socioemocional" component={SocioemotionalExamProduct} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("main")
)
