import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router"
import { logout } from "home/actions"
import API from "api"

class MenuMobileLogout extends React.Component {
  constructor (props) {
    super(props)
    this.state = { fireRedirect: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    API.delete("/sessions", () => {
      this.props.dispatch(logout())
    }).then(this.setState({ fireRedirect: true }))
  }

  render () {
    const { fireRedirect } = this.state

    if (fireRedirect) {
      return <Redirect to="/entrar" />
    }

    return (
      <div className="mobile-menu-text mobile-menu-logout">
        <span onClick={this.handleClick}>Sair</span>
      </div>
    )
  }
}

export default connect()(MenuMobileLogout)
