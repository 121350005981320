import BnccStudentPerformanceTablePresentation from "home/components/bncc_proficiency_analysis/presentations/student_performance_table_presentation"
import BnccAnswerResultTablePresentation from "home/components/bncc_proficiency_analysis/presentations/answer_result_table_presentation"
import AbilityByAreaChartPresentation from "home/components/bncc_proficiency_analysis/presentations/ability_by_area_chart_presentation"
import ThematicUnitByAreaChartPresentation from "home/components/bncc_proficiency_analysis/presentations/thematic_unit_by_area_chart_presentation"

export {
  BnccStudentPerformanceTablePresentation,
  BnccAnswerResultTablePresentation,
  AbilityByAreaChartPresentation,
  ThematicUnitByAreaChartPresentation
}
