export const pageWhiteList = ["/perfil", "/missao-diaria"]

const pageList = [
  {
    id: "profile",
    caption: "Perfil Geral",
    icon: "profile",
    border_color: "orange",
    link: "/perfil",
  },
  {
    id: "daily-mission",
    caption: "Missão diária",
    icon: "daily-training",
    border_color: "green",
    link: "/missao-diaria",
  },
  {
    id: "enem-exams",
    caption: "Avaliações ENEM",
    icon: "test",
    border_color: "blue",
    link: "/avaliacoes-enem",
  },
  {
    id: "saeb-exams",
    caption: "Avaliações SAEB",
    icon: "test",
    border_color: "blue",
    link: "/avaliacoes-saeb",
  },
  {
    id: "bncc-exams",
    caption: "Avaliações BNCC",
    icon: "test",
    border_color: "blue",
    link: "/avaliacoes-bncc",
  },
  {
    id: "enem-proficiency-analysis",
    caption: "Análises de Proficiência (ENEM)",
    icon: "consulting-analysis",
    border_color: "red",
    link: "/analises-de-proficiencia-enem",
  },
  {
    id: "saeb-proficiency-analysis",
    caption: "Análises de Proficiência (SAEB)",
    icon: "consulting-analysis",
    border_color: "red",
    link: "/analises-de-proficiencia-saeb",
  },
  {
    id: "bncc-proficiency-analysis",
    caption: "Análises de Proficiência (BNCC)",
    icon: "consulting-analysis",
    border_color: "red",
    link: "/analises-de-proficiencia-bncc",
  },
  {
    id: "socioemotional",
    caption: "Avaliação Socioemocional",
    icon: "socioemocional",
    border_color: "purple",
    link: "/avaliacoes-socioemocional",
  },
]

export const pages = pageList.filter((page) => pageWhiteList.includes(page.link))
