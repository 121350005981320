import React, { Component } from "react"
import Product from "home/components/product"
import { CaptionedDivWithFilter, Collapsible } from "home/components/layout"

import {
  FinalizedExamsBadgeContainer,
  FinalizedExamsChartContainer,
  PerformanceTableContainer,
  AbilityByAreaChartContainer,
  AbilityChartContainer
} from "home/components/socioemotional"

import { KnowledgeAreaFilter } from "home/components/filters"
import { MixpanelTrack } from "home/mixpanel"

class SocioemotionalExamProduct extends Component {
  componentDidMount () {
    MixpanelTrack("Acessou Produto Avaliações SocioEmocionais")
  }

  render () {
    return (
        <Product caption="Avaliações SocioEmocionais">
          <div className="grid grid-thirds">
            <div className="grid-cell">
                <div className="product-element">
                    <div className="badge-container">
                        <FinalizedExamsBadgeContainer />
                        <Collapsible>
                            <FinalizedExamsChartContainer />
                        </Collapsible>
                    </div>
                </div>
            </div>
            <div className="grid-cell">
              <CaptionedDivWithFilter
                filterType={KnowledgeAreaFilter}
                filterKey="enemAnalysis/ability/knowledgeArea"
                filterCategory="ENEM"
                caption="Habilidades"
              >
                <AbilityChartContainer />
              </CaptionedDivWithFilter>
              <div className="product-element-footer" />
            </div>
            <div className="grid-cell">
              <CaptionedDivWithFilter
                filterType={KnowledgeAreaFilter}
                filterKey="socioemotional/knowledgeArea"
                filterCategory="SOCIOEMOTIONAL"
                caption="Habilidades"
              >
                <AbilityByAreaChartContainer />
              </CaptionedDivWithFilter>
              <div className="product-element-footer" />
            </div>
          </div>
          <div>
            <div className="product-element">
              <PerformanceTableContainer />
            </div>
          </div>
        </Product>
      )
  }
}

export default SocioemotionalExamProduct