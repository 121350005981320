import React, { PureComponent } from "react"

import {
  SideMenu,
  Logout,
  MobileMenuButton
} from "home/components/layout"

export default class Logo extends PureComponent {
  render () {
    return (
      <header>
        <div className="grid-container">
          <div className="grid grid-gutters-paddding">
            <div className="grid-cell">
              <span className="logo-escola360" />
            </div>
            <div className="grid-cell text-right">
              <Logout />
              <MobileMenuButton />
              <SideMenu />
            </div>
          </div>
        </div>
      </header>
    )
  }
}
