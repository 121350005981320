import { env, mixpanelToken } from "config"
import mixpanel from "mixpanel-browser"

const shouldTrack = env === "prod"

const MixpanelInitialize = () => {
  if (shouldTrack) {
    mixpanel.init(mixpanelToken)
  }
}

const MixpanelTrack = (event, props) => {
  if (shouldTrack) {
    mixpanel.track(event, props)
  }
}

const MixpanelReset = () => {
  if (shouldTrack) {
    mixpanel.reset()
  }
}

const MixpanelTrackFilter = filters => {
  if (shouldTrack) {
    const filtersJoined = filters.join(" e ")
    mixpanel.track(`Aplicou filtro ${filtersJoined}`)
    mixpanel.track("Aplicou Filtro (Geral)")
  }
}

const MixpanelSignIn = user => {
  if (shouldTrack) {
    mixpanel.identify(user.id)
    mixpanel.people.set({
      $email: user.email,
      schools: user.schools.map(school => school.id)
    })
  }
}

export { MixpanelInitialize, MixpanelTrack, MixpanelReset, MixpanelSignIn, MixpanelTrackFilter }
