import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { buildUrl } from "home/api_helper"
import { MixpanelTrack } from "home/mixpanel"
import Spinner from "home/components/layout/spinner"
import XLSX from "xlsx"
import API from "api"

class DataTableFooter extends React.Component {
  constructor (props) {
    super(props)
    this.state = { downloading: false }
  }

  handleDownload (filters) {
    this.setState({ downloading: true })

    const { resource } = this.props
    const completeUrl = buildUrl(resource, filters, "json/complete")

    API.get(
      completeUrl,
      (response) => {
        const { rows, filename } = response.data

        const ws = XLSX.utils.aoa_to_sheet(rows)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Escola 360º")
        XLSX.writeFile(wb, filename)

        MixpanelTrack(`Baixou ${filename.replace(/_?\d+[_-]?(.xlsx)?/g, "")}`)
        this.setState({ downloading: false })
      },
      () => {
        this.setState({ downloading: false })
      }
    )
  }

  handleDownloadEvaluations (filters) {
    this.setState({ downloading: true })

    API.get(
      filters,
      (response) => {
        console.log(response)
        this.setState({ downloading: false })
      },
      () => {
        this.setState({ downloading: false })
      }
    )
  }

  render () {
    const { rowsCount, hasFilters, filters } = this.props
    const { downloading } = this.state

    return (
      <div className="smart-table-footer-container">
        {rowsCount && <div className="smart-table-footer-text">{rowsCount} linhas nesta tabela</div>}

        <div className="smart-table-download-container">
          {downloading && <Spinner />}

          {!downloading && (
            <span className="smart-table-filtered-download-container">
              <span className={hasFilters ? "smart-table-download-icon" : "smart-table-download-icon-disabled"} />
              <Link
                to="#"
                className={
                  hasFilters ? "smart-table-filtered-download-text" : "smart-table-filtered-download-text-disabled"
                }
                onClick={() => this.handleDownload(filters)}
              >
                BAIXAR TABELA FILTRADA
              </Link>
            </span>
          )}

          {!downloading && (
            <span className="smart-table-complete-download-container">
              <span className="smart-table-download-icon" />
              <Link
                to="#"
                className="smart-table-download-text"
                onClick={() => this.handleDownload({ schoolId: filters.schoolId })}
              >
                BAIXAR TABELA COMPLETA
              </Link>
            </span>
          )}

          {/* {!downloading && (
            <span className="smart-table-complete-download-container ml-20">
              <span className="smart-table-download-icon" />
              <a
                href={MockedPDF}
                target={"_blank"}
                download
                rel="noopener noreferrer"
                className="smart-table-download-text"
                onClick={() => this.handleDownloadEvaluations({ schoolId: filters.schoolId })}
              >
                BAIXAR RELATÓRIOS
              </a>
            </span>
          )} */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
  }
}

export default connect(mapStateToProps)(DataTableFooter)
