import React, { PureComponent } from "react"
import { Link } from "react-router-dom"

class ProductBoxAvailable extends PureComponent {
  render () {
    return (
      <div className={"product-box " + this.props.border_color + "-border"}>
        <Link to={this.props.to} className="block-element">
          <span className={"product-box-icon " + this.props.icon} />
          <span className="product-box-label">{this.props.caption}</span>
          <span
            className={"product-box-link " + this.props.border_color + "-link"}
          >
            <span className="product-box-link-icon" />
          </span>
        </Link>
      </div>
    )
  }
}

export default ProductBoxAvailable
