import React, { Component } from "react"
import { Redirect } from "react-router"
import { connect } from "react-redux"
import { updateCurrentUser, setFilter } from "home/actions"
import { MixpanelTrack, MixpanelSignIn } from "home/mixpanel"
import { storeAuthToken } from "auth"
import API from "api"

class SessionNew extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      fireRedirect: false,
      error: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  handleSubmit (event) {
    const { email, password } = this.state
    console.log(email, password)

    API.post(
      "/sessions",
      { session: { email, password } },
      (response) => {
        const user = response.data
        const { token } = user

        storeAuthToken(token)

        MixpanelSignIn(user)
        MixpanelTrack("Efetuou login")

        this.props.dispatch(updateCurrentUser(user))
        this.props.dispatch(setFilter({ schoolId: user.schools[0].id }))
        this.setState({ fireRedirect: true })
      },
      (error) => {
        this.setState({ error: error.response.data.error, password: "" })
      }
    )

    event.preventDefault()
  }

  render () {
    const { fireRedirect, error } = this.state

    if (fireRedirect) {
      return <Redirect to="/" />
    }

    return (
      <div className="login-container grid grid-center grid-justify-center">
        {error && (
          <div className="error-wrapper">
            <span className="error-message">{error}</span>
            <a href="#" className="close-error-message">
              x
            </a>
          </div>
        )}

        <div className="login-form-arrow-icon">
          <div className="login-form">
            <span className="logo-escola-360-login" />
            <span className="spacer margin-top-30 margin-bottom-30" />

            <form onSubmit={this.handleSubmit}>
              <div className="grid-flex margin-bottom-20">
                <label className="text-uppercase">Usuário</label>
                <input id="email" type="text" className="login-input-text" name="email" onChange={this.handleChange} />
              </div>

              <div className="grid-flex">
                <label className="text-uppercase">Senha</label>
                <input
                  id="password"
                  type="password"
                  className="login-input-text"
                  name="password"
                  onChange={this.handleChange}
                />
              </div>

              <div>
                <input type="submit" className="btn btn-primary margin-top-20 margin-bottom-20" value="Entrar" />
              </div>
            </form>

            <div className="login-footer">
              <small className="text-uppercase">Dúvidas? Fale com o nosso time de atendimento:</small>

              <small>
                <a href="tel:113474-1770">(11) 3474-1770</a> |{" "}
                <a href="mailto:ajuda@mindlab.com.br">ajuda@mindlab.com.br</a>
              </small>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(SessionNew)
