import React, { PureComponent } from "react"

class ProductBoxNotImplemented extends PureComponent {
  render () {
    return (
      <div className="product-box soon">
        <p className="text-uppercase">{this.props.caption}</p>
        <p>(em breve)</p>
      </div>
    )
  }
}

export default ProductBoxNotImplemented
