import React from "react"
import { connect } from "react-redux"
import { setMobileMenuVisibility } from "home/actions"
import { Link } from "react-router-dom"

const MobileMenuOption = ({ dispatch, url, label }) => {
  return (
    <li className="mobile-menu-option">
      <Link
        to={url}
        onClick={() => dispatch(setMobileMenuVisibility(false))}
        className="grid grid-justify-between grid-center"
      >
        <span className="mobile-menu-text">{label}</span>
        <span className="mobile-menu-arrow-right" />
      </Link>
    </li>
  )
}

export default connect()(MobileMenuOption)
