import React from "react"
import PropTypes from "prop-types"

class Collapsible extends React.Component {
  constructor (props) {
    super(props)

    if (this.props.open) {
      this.state = {
        isClosed: false,
        shouldSwitchAutoOnNextCycle: false,
        height: "auto",
        transition: "none",
        hasBeenOpened: true,
        overflow: this.props.overflowWhenOpen
      }
    }

    this.state = {
      isClosed: true,
      shouldSwitchAutoOnNextCycle: false,
      height: 0,
      transition: "height " + this.props.transitionTime + "ms " + this.props.easing,
      hasBeenOpened: false,
      overflow: "hidden"
    }
    this.handleTriggerClick = this.handleTriggerClick.bind(this)
  }

  // Taken from https://github.com/EvandroLG/transitionEnd/
  // Determines which prefixed event to listen for
  whichTransitionEnd (element) {
    const transitions = [
      {
        WebkitTransition: "webkitTransitionEnd"
      },
      {
        MozTransition: "transitionend"
      },
      {
        OTransition: "oTransitionEnd otransitionend"
      },
      {
        transition: "transitionend"
      }
    ]

    return transitions.find(t => element.style[t] !== undefined)
  }

  componentDidMount () {
    this.outerRef.addEventListener(this.whichTransitionEnd(this.outerRef), () => {
      if (this.state.isClosed === false) {
        this.setState({
          shouldSwitchAutoOnNextCycle: true
        })
      }
    })
  }

  componentDidUpdate (prevProps) {
    if (this.state.shouldSwitchAutoOnNextCycle === true && this.state.isClosed === false) {
      //Set the height to auto to make compoenent re-render with the height set to auto.
      //This way the dropdown will be responsive and also change height if there is another dropdown within it.
      this.makeResponsive()
    }

    if (this.state.shouldSwitchAutoOnNextCycle === true && this.state.isClosed === true) {
      this.prepareToOpen()
    }

    //If there has been a change in the open prop (controlled by accordion)
    if (prevProps.open !== this.props.open) {
      if (this.props.open === true) {
        this.openCollapsible()
      } else {
        this.closeCollapsible()
      }
    }
  }

  handleTriggerClick (event) {
    event.preventDefault()

    if (this.props.handleTriggerClick) {
      this.props.handleTriggerClick(this.props.accordionPosition)
    } else if (this.state.isClosed === true) {
      this.openCollapsible()
    } else {
      this.closeCollapsible()
    }
  }

  closeCollapsible () {
    this.setState({
      isClosed: true,
      shouldSwitchAutoOnNextCycle: true,
      height: this.innerRef.offsetHeight,
      overflow: "hidden"
    })
  }

  openCollapsible () {
    this.setState({
      height: this.innerRef.offsetHeight,
      transition: "height " + this.props.transitionTime + "ms " + this.props.easing,
      isClosed: false,
      hasBeenOpened: true
    })
  }

  makeResponsive () {
    this.setState({
      height: "auto",
      transition: "none",
      shouldSwitchAutoOnNextCycle: false,
      overflow: this.props.overflowWhenOpen
    })
  }

  prepareToOpen () {
    //The height has been changes back to fixed pixel, we set a small timeout to force the CSS transition back to 0 on the next tick.
    window.setTimeout(() => {
      this.setState({
        height: 0,
        shouldSwitchAutoOnNextCycle: false,
        transition: "height " + this.props.transitionTime + "ms " + this.props.easing
      })
    }, 50)
  }

  render () {
    const dropdownStyle = {
      height: this.state.height,
      WebkitTransition: this.state.transition,
      msTransition: this.state.transition,
      transition: this.state.transition,
      overflow: this.state.overflow
    }

    const openClass = this.state.isClosed ? "is-closed" : "is-open"

    //If user wants different text when tray is open
    const trigger =
      this.state.isClosed === false && this.props.triggerWhenOpen !== undefined
        ? this.props.triggerWhenOpen
        : this.props.trigger

    // Don't render children until the first opening of the Collapsible if lazy rendering is enabled
    let children = this.props.children

    if (this.props.lazyRender) {
      if (!this.state.hasBeenOpened) {
        children = null
      }
    }

    return (
      <div className={this.props.classParentString}>
        <div
          className={this.props.classParentString + "__contentOuter"}
          ref={outer => {
            this.outerRef = outer
          }}
          style={dropdownStyle}
        >
          <div
            className={this.props.classParentString + "__contentInner"}
            ref={inner => {
              this.innerRef = inner
            }}
          >
            {children}
          </div>
        </div>
        <span
          className={this.props.classParentString + "--trigger" + " " + openClass}
          onClick={this.handleTriggerClick}
        >
          {trigger}
        </span>
      </div>
    )
  }
}

Collapsible.defaultProps = {
  transitionTime: 400,
  easing: "linear",
  open: false,
  classParentString: "collapsible",
  lazyRender: false,
  overflowWhenOpen: "hidden",
  trigger: <span className="expand-btn" />
}

Collapsible.propTypes = {
  children: PropTypes.node,
  transitionTime: PropTypes.number,
  easing: PropTypes.string,
  open: PropTypes.bool,
  classParentString: PropTypes.string,
  accordionPosition: PropTypes.number,
  handleTriggerClick: PropTypes.func,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  triggerWhenOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  lazyRender: PropTypes.bool,
  overflowWhenOpen: PropTypes.oneOf(["hidden", "visible", "auto", "scroll", "inherit", "initial", "unset"])
}

export default Collapsible
