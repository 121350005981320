import React from "react"
import { Route, Redirect } from "react-router"
import { isAuthenticated } from "auth"
import Main from "home/main"

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) =>
      isAuthenticated() ? (
        <Main location={location}>
          <Component location={location} {...props} />
        </Main>
      ) : (
        <Redirect to={{ pathname: "/entrar", state: { from: location } }} />
      )
    }
  />
)

export default AuthenticatedRoute
