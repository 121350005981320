import React from "react"
import Product from "home/components/product"
import { CaptionedDivWithFilter } from "home/components/layout"
import { BnccExamSessionsTableContainer, BnccExamPerformanceByAreaChartContainer } from "home/components/bncc_exam"
import { BnccExamFilter } from "home/components/filters"
import { MixpanelTrack } from "home/mixpanel"

const BnccExamProduct = () => {
  MixpanelTrack("Acessou Produto Avaliações BNCC")

  return (
    <Product caption="Avaliações BNCC">
      <div className="grid">
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={BnccExamFilter}
            filterKey="bnccExams/performanceByArea"
            filterCategory="BNCC"
            caption="Desempenho por Area"
          >
            <BnccExamPerformanceByAreaChartContainer />
          </CaptionedDivWithFilter>
          <div className="product-element-footer" />
        </div>
      </div>
      <div className="grid">
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={BnccExamFilter}
            filterCategory="BNCC"
            filterKey="bnccExams/sessions/examId"
            caption="Dados e Desempenho por Aluno"
          >
            <BnccExamSessionsTableContainer />
          </CaptionedDivWithFilter>
        </div>
      </div>
    </Product>
  )
}

export default BnccExamProduct
