import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { SchoolInfoPresentation } from "home/components/profile"

class SchoolInfoContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      schoolName: "-",
      schoolInep: null,
      schoolMindlabCustomerSuccess: "",
      schoolCity: "",
      schoolState: "",
      schoolContactPersonPhone: "",
      schoolContactPersonName: "",
      schoolContactPersonJobTitle: "",
      schoolContactPersonEmail: "",
      schoolMindlabSalesman: "",
      schoolMindlabTrainer: "",
      schoolHtmlInstructions: "",
      schoolCategory: ""
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters.schoolId !== this.props.filters.schoolId) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("profile/school_info", filters, response => this.setSchoolInfoFromResponse(response))
  }

  setSchoolInfoFromResponse (response) {
    this.setState({
      schoolName: response.data.school_name,
      schoolInep: response.data.school_inep,
      schoolMindlabCustomerSuccess: response.data.school_mindlab_customer_success,
      schoolCity: response.data.school_city,
      schoolState: response.data.school_state,
      schoolContactPersonPhone: response.data.school_contact_person_phone,
      schoolContactPersonName: response.data.school_contact_person_name,
      schoolContactPersonJobTitle: response.data.school_contact_person_job_title,
      schoolContactPersonEmail: response.data.school_contact_person_email,
      schoolMindlabSalesman: response.data.school_mindlab_salesman,
      schoolMindlabTrainer: response.data.school_mindlab_trainer,
      schoolHtmlInstructions: response.data.school_html_instructions,
      schoolCategory: response.data.school_category
    })
  }

  render () {
    return (
      <SchoolInfoPresentation
        schoolName={this.state.schoolName}
        schoolInep={this.state.schoolInep}
        schoolCity={this.state.schoolCity}
        schoolState={this.state.schoolState}
        schoolMindlabCustomerSuccess={this.state.schoolMindlabCustomerSuccess}
        schoolContactPersonPhone={this.state.schoolContactPersonPhone}
        schoolContactPersonName={this.state.schoolContactPersonName}
        schoolContactPersonJobTitle={this.state.schoolContactPersonJobTitle}
        schoolContactPersonEmail={this.state.schoolContactPersonEmail}
        schoolMindlabSalesman={this.state.schoolMindlabSalesman}
        schoolMindlabTrainer={this.state.schoolMindlabTrainer}
        schoolHtmlInstructions={this.state.schoolHtmlInstructions}
        schoolCategory={this.state.schoolCategory}

      />
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(SchoolInfoContainer)
