import React, { Component } from "react"
import Product from "home/components/product"
import { CaptionedDivWithFilter } from "home/components/layout"
import { SaebExamSessionsTableContainer, SaebExamPerformanceByAreaContainer } from "home/components/saeb_exam"
import { SaebExamFilter } from "home/components/filters"
import { MixpanelTrack } from "home/mixpanel"

class SaebExamProduct extends Component {
  componentDidMount () {
    MixpanelTrack("Acessou Produto Avaliações SAEB")
  }

  render () {
    return (
      <Product caption="Avaliações SAEB">
        <div className="grid">
          <div className="grid-cell">
            <CaptionedDivWithFilter
              filterType={SaebExamFilter}
              filterKey="saebExams/performanceByArea"
              filterCategory="SAEB"
              caption="Desempenho Matemática e Português"
            >
              <SaebExamPerformanceByAreaContainer />
            </CaptionedDivWithFilter>
            <div className="product-element-footer" />
          </div>
        </div>

        <div>
          <div className="product-element">
            <CaptionedDivWithFilter
              filterType={SaebExamFilter}
              filterCategory="SAEB"
              filterKey="saebExams/performanceByStudent"
              caption="Dados e Desempenho por Aluno"
            >
              <SaebExamSessionsTableContainer />
            </CaptionedDivWithFilter>
          </div>
        </div>
      </Product>
    )
  }
}

export default SaebExamProduct
