import React from "react"
import Product from "home/components/product"
import { MixpanelTrack } from "home/mixpanel"
import { CaptionedDivWithFilter } from "home/components/layout"
import {
  BnccStudentPerformanceTableContainer,
  BnccAnswerResultTableContainer,
  AbilityByAreaChartContainer,
  ThematicUnitByAreaChartContainer
} from "home/components/bncc_proficiency_analysis"

import { BnccStudentPerformanceFilter, BnccAnswerResultFilter, KnowledgeAreaFilter } from "home/components/filters"

const BnccProficiencyAnalysisProduct = () => {
  MixpanelTrack("Acessou Produto Análise de Proeficiências BNCC")

  return (
    <Product caption="ANÁLISE DE PROFICIÊNCIAS (BNCC)">
      <div>
        <div className="grid grid-double">
          <div className="grid-cell">
            <CaptionedDivWithFilter
              filterType={KnowledgeAreaFilter}
              filterCategory="BNCC"
              filterKey="bnccAnalysis/ability/knowledgeArea"
              caption="Habilidades"
            >
              <AbilityByAreaChartContainer />
            </CaptionedDivWithFilter>
            <div className="product-element-footer" />
          </div>
          <div className="grid-cell">
            <CaptionedDivWithFilter
              filterType={KnowledgeAreaFilter}
              filterCategory="BNCC"
              filterKey="bnccAnalysis/thematicUnit/knowledgeArea"
              caption="Unidades Temáticas"
            >
              <ThematicUnitByAreaChartContainer />
            </CaptionedDivWithFilter>
            <div className="product-element-footer" />
          </div>
        </div>
        <div className="product-element">
          <CaptionedDivWithFilter
            filterType={BnccStudentPerformanceFilter}
            filterKey="bnccAnalysis/studentPerformance/examId"
            filterCategory="BNCC"
            caption="Proficiência por aluno"
          >
            <BnccStudentPerformanceTableContainer />
          </CaptionedDivWithFilter>
        </div>
        <div className="grid">
          <div className="grid-cell">
            <CaptionedDivWithFilter
              filterType={BnccAnswerResultFilter}
              filterCategory="BNCC"
              filterKey="bnccAnalysis/answerResult/examId"
              caption="Acertos / Erros por questão"
            >
              <BnccAnswerResultTableContainer />
            </CaptionedDivWithFilter>
          </div>
        </div>
      </div>
    </Product>
  )
}

export default BnccProficiencyAnalysisProduct
