import TopicChartPresentation from "home/components/saeb_proficiency_analysis/presentations/topic_chart_presentation"
import DescriptorChartPresentation from "home/components/saeb_proficiency_analysis/presentations/descriptor_chart_presentation"
import SaebStudentPerformanceTablePresentation from "home/components/saeb_proficiency_analysis/presentations/student_performance_table_presentation"
import SaebAnswerResultTablePresentation from "home/components/saeb_proficiency_analysis/presentations/answer_result_table_presentation"

export {
  TopicChartPresentation,
  DescriptorChartPresentation,
  SaebStudentPerformanceTablePresentation,
  SaebAnswerResultTablePresentation
}
