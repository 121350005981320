import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { FinalizedExamsBadgePresentation } from "home/components/profile"

class FinalizedExamsBadgeContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      total: 0,
      finalized: 0
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters.schoolId !== this.props.filters.schoolId) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("profile/finalized_exams", filters, response => this.setBadgeConfigFromResponse(response))
  }

  setBadgeConfigFromResponse (response) {
    this.setState({
      finalized: response.data.finalized_exams,
      total: response.data.total_exams
    })
  }

  render () {
    return <FinalizedExamsBadgePresentation finalized={this.state.finalized} total={this.state.total} />
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(FinalizedExamsBadgeContainer)
