import React from "react"
import ReactHighcharts from "react-highcharts"

const baseChart = {
  chart: {
    type: "bar"
  },
  title: {
    text: "Proficiência (%) em Tópicos"
  },
  yAxis: {
    min: 0,
    max: 100,
    tickAmount: 6,
    title: {
      text: "Proficiência (%)"
    }
  },
  legend: {
    symbolHeight: 0,
    symbolPadding: 0,
    labelFormatter () {
      return ""
    },
    symbolWidth: 10
  },
  plotOptions: {
    bar: {
      events: {
        legendItemClick () {
          const series = this.chart.series
          series.forEach(function (serie) {
            serie.hide()
          })

          const newDescriptors = []

          series[this.index]["data"].forEach((data, index) => {
            newDescriptors[index] = data["name"]
          })

          this.chart.xAxis[0].setCategories(newDescriptors)
        }
      },
      dataLabels: {
        format: "{point.y}%",
        enabled: true,
        style: {
          textOutline: false
        }
      }
    }
  },
  tooltip: {
    useHTML: true,
    headerFormat: "",
    pointFormat:
      "<div style=\"width: 200px; white-space:normal;text-align: center;\">" +
      "<span style=\"color:#00c89d\">{point.correct_answers} acertos </span> " +
      "e <span style=\"color:#e50b05\">{point.wrong_answers} erros </span>" +
      "<br/> nesse tópico" +
      "<br><br><b>Descrição:</b>" +
      "<br>{point.description}</div>"
  }
}

class TopicChartPresentation extends React.PureComponent {
  render () {
    const mergedChartConfig = Object.assign({}, baseChart, this.props.patch)

    return <ReactHighcharts config={mergedChartConfig} />
  }
}

export default TopicChartPresentation
