import React from "react"
import Product from "home/components/product"
import { MixpanelTrack } from "home/mixpanel"
import { CaptionedDivWithFilter } from "home/components/layout"

import {
  SaebStudentPerformanceTableContainer,
  SaebAnswerResultTableContainer,
  TopicChartContainer,
  DescriptorChartContainer
} from "home/components/saeb_proficiency_analysis"

import {
  SaebStudentPerformanceFilter,
  SaebAnswerResultFilter,
  KnowledgeAreaFilter
} from "home/components/filters"

const SaebProficiencyAnalysisProduct = () => {
  MixpanelTrack("Acessou Produto Análise de Proeficiências SAEB")

  return (
    <Product caption="ANÁLISE DE PROFICIÊNCIAS (SAEB)">
      <div className="grid grid-double">
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={KnowledgeAreaFilter}
            filterKey="saebAnalysis/topic/knowledgeArea"
            filterCategory="SAEB"
            caption="Tópicos"
          >
            <TopicChartContainer />
          </CaptionedDivWithFilter>
          <div className="product-element-footer" />
        </div>
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={KnowledgeAreaFilter}
            filterKey="saebAnalysis/descriptor/knowledgeArea"
            filterCategory="SAEB"
            caption="Descritores"
          >
            <DescriptorChartContainer />
          </CaptionedDivWithFilter>
          <div className="product-element-footer" />
        </div>
      </div>
      <div className="grid">
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={SaebStudentPerformanceFilter}
            filterKey="saebAnalysis/studentPerformance/examId"
            caption="Proficiência por aluno"
            filterCategory="SAEB"
          >
            <SaebStudentPerformanceTableContainer />
          </CaptionedDivWithFilter>
        </div>
      </div>
      <div className="grid">
        <div className="grid-cell">
          <CaptionedDivWithFilter
            filterType={SaebAnswerResultFilter}
            filterKey="saebAnalysis/answerResult/examId"
            filterCategory="SAEB"
            caption="Acertos/ Erros por questão"
          >
            <SaebAnswerResultTableContainer />
          </CaptionedDivWithFilter>
        </div>
      </div>
    </Product>
  )
}

export default SaebProficiencyAnalysisProduct
