import React from "react"

const FinalizedExamsBadgePresentation = ({ finalized, total }) => (
  <div className="finalized-exams-badge text-center">
    <div className="inline-block-element">
      <span className="finalized-exams-badge-icon" />
    </div>
    <div className="inline-block-element text-left">
      <span className="badge-container-count">{finalized}</span>
      <span className="badge-container-label">avaliações finalizadas</span>
      <span className="badge-container-small-label">de {total} disponíveis</span>
    </div>
  </div>
)
export default FinalizedExamsBadgePresentation
