import React, { PureComponent } from "react"
import ReactHighcharts from "react-highcharts"

const baseChart = {
  chart: {
    type: "area"
  },
  title: {
    text: null
  },
  colors: ["#05d2c7"],
  xAxis: {
    type: "datetime",
    tickInterval: 24 * 3600 * 1000,
    dateTimeLabelFormats: {
      month: "%b",
      year: "%b"
    }
  },
  yAxis: {
    title: {
      text: "Número de Questões"
    }
  },
  tooltip: {
    headerFormat: "<b>{point.x:%e de %B}</b><br/>",
    pointFormat: "{point.y} questões respondidas"
  }
}

class DailyMissionAccumulatedChartPresentation extends PureComponent {
  render () {
    const mergedChartConfig = Object.assign({}, baseChart, this.props.patch)

    return <ReactHighcharts config={mergedChartConfig} isPureConfig />
  }
}

export default DailyMissionAccumulatedChartPresentation
