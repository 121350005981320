import { SET_FILTER, SET_PRODUCTS, UPDATE_CURRENT_USER, SET_MOBILE_MENU_VISIBILITY } from "home/actions"
import moment from "moment"

const filtersInitialState = {
  schoolYear: "ALL",
  schoolClass: "ALL",
  examId: "ALL",
  userId: "ALL",
  schoolId: "",
  knowledgeArea: "",
  dateFilterStartDate: moment().format("YYYY") + "-01-01",
  dateFilterEndDate: moment().format("YYYY-MM-DD"),
  "enemExams/performanceByArea": "ALL",
  "enemExams/sessions/examId": "ALL",
  "bnccExams/performanceByArea": "ALL",
  "bnccExams/sessions/examId": "ALL",
  "bnccAnalysis/studentPerformance/examId": "ALL",
  "bnccAnalysis/answerResult/examId": "ALL",
  "bnccAnalysis/ability/knowledgeArea": "",
  "bnccAnalysis/thematicUnit/knowledgeArea": "",
  "saebExams/performanceByArea": "ALL",
  "saebExams/performanceByStudent": "ALL",
  "enemAnalysis/competence/knowledgeArea": "",
  "enemAnalysis/ability/knowledgeArea": "",
  "enemAnalysis/studentPerformance/examId": "ALL",
  "enemAnalysis/answerResult/examId": "ALL",
  "saebAnalysis/topic/knowledgeArea": "",
  "saebAnalysis/descriptor/knowledgeArea": "",
  "saebAnalysis/studentPerformance/examId": "ALL",
  "saebAnalysis/answerResult/examId": "ALL"
}

const productsInitialState = []

const authInitialState = {
  currentUser: null
}

const auth = (state = authInitialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        currentUser: action.user
      })
    default:
      return state
  }
}

const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return Object.assign({}, state, { [action.filter.name]: action.filter.value })
    default:
      return state
  }
}

const products = (state = productsInitialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return action.products
    default:
      return state
  }
}

const isMobileMenuVisible = (state = false, action) => {
  switch (action.type) {
    case SET_MOBILE_MENU_VISIBILITY:
      return action.isMobileMenuVisible
    default:
      return state
  }
}

export default {
  filters,
  products,
  auth,
  isMobileMenuVisible
}
