import React, { PureComponent } from "react"
import { ProductBox } from "home/components/layout"
import { pages } from "../../../pages"
class ProductsListPresentation extends PureComponent {
  productAvailability (product_name) {
    return this.props.products.includes(product_name) ? ProductBox.AVAILABLE : ProductBox.UNAVAILABLE
  }

  render () {
    return (
      <div className="new-grid-for-360">
        {pages.map((page) => (
          <ProductBox
            key={page.id}
            caption={page.caption}
            icon={page.icon}
            border_color={page.border_color}
            to={page.link}
            kind={this.productAvailability(page.id)}
          />
        ))}
      </div>
    )
  }
}

export default ProductsListPresentation
