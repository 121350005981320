import React from "react"
import Product from "home/components/product"
import {
  SchoolInfoContainer,
  DailyMissionAnsweredBadgeContainer,
  DailyMissionAnsweredChartContainer,
  LicencesActivationsBadgeContainer,
  LicencesActivationsChartContainer,
  FinalizedExamsBadgeContainer,
  FinalizedExamsChartContainer,
  PerformanceTableContainer
} from "home/components/profile"

import { CaptionedDiv, Collapsible } from "home/components/layout"
import { MixpanelTrack } from "home/mixpanel"

const ProfileProduct = () => {
  MixpanelTrack("Acessou Produto Perfil Geral")

  return (
    <Product caption="Perfil geral">
      <div className="grid grid-thirds">
        <div className="grid-cell">
          <div className="product-element">
            <div className="badge-container">
              <SchoolInfoContainer />
            </div>
          </div>
        </div>
        <div className="grid-cell">
          <div className="product-element">
            <div className="badge-container">
              <LicencesActivationsBadgeContainer />
              <Collapsible>
                <LicencesActivationsChartContainer />
              </Collapsible>
            </div>
          </div>
          <div className="product-element">
            <div className="badge-container">
              <FinalizedExamsBadgeContainer />
              <Collapsible>
                <FinalizedExamsChartContainer />
              </Collapsible>
            </div>
          </div>
        </div>
        <div className="grid-cell">
          <div className="product-element">
            <div className="badge-container">
              <DailyMissionAnsweredBadgeContainer />
              <Collapsible>
                <DailyMissionAnsweredChartContainer />
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="product-element">
          <CaptionedDiv caption="Engajamento e Desempenho por aluno">
            <PerformanceTableContainer />
          </CaptionedDiv>
        </div>
      </div>
    </Product>
  )
}

export default ProfileProduct
