import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Header from "home/components/layout/header"
import Footer from "home/components/layout/footer"
import { setProducts } from "home/actions"
import MobileMenu from "home/components/layout/mobile/mobile_menu"
import { setContentPadding } from "home/components/layout/content"
import API from "api"

class Main extends React.Component {
  componentDidMount () {
    API.get("/products", response => {
      //const data = [...response.data, "socioemotional"]
      // console.log("Data",data)
      this.props.dispatch(setProducts(response.data))
    })
    setContentPadding()
  }

  render () {
    const { children, location } = this.props

    return (
      <div>
        <MobileMenu />
        <Header location={location.pathname} />
        <div id="content" className="grid-container">
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ])
}

export default connect()(Main)
