import { createStore, compose, applyMiddleware } from "redux"
import { persistStore, persistCombineReducers } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import Raven from "raven-js"
import createRavenMiddleware from "raven-for-redux"
import createEncryptor from "redux-persist-transform-encrypt"
import reducers from "home/reducers"
import { env, publicCryptoKey, sentryDsnPublic } from "config"

export const STORAGE_KEY = "aquarius"

Raven.config(sentryDsnPublic).install()

let persistConfig = {
  key: STORAGE_KEY,
  storage
}

if (env === "prod") {
  const encryptor = createEncryptor({ secretKey: publicCryptoKey })
  persistConfig = Object.assign(persistConfig, { transforms: [encryptor] })
}

const persistedReducer = persistCombineReducers(persistConfig, reducers)

export function initialState () {
  return persistedReducer
}

function getUserContext (state) {
  return state.auth.currentUser
}

function resetMiddleware () {
  return next => (reducer, initialState, enhancer) => {
    const enhanceReducer = (state, action) => {
      if (action.type === "RESET") {
        state = undefined
      }
      return reducer(state, action)
    }

    return next(enhanceReducer, initialState, enhancer)
  }
}

export function initStore () {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(thunk),
      applyMiddleware(createRavenMiddleware(Raven, { getUserContext: getUserContext })),
      resetMiddleware()
    )
  )
  const persistor = persistStore(store)
  return { store, persistor }
}
