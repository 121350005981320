import React from "react"

const LicencesActivationsBadgePresentation = ({ activations, licences }) => (
  <div className="licences-activations-badge text-center">
    <div className="inline-block-element">
      <span className="licences-activations-badge-icon" />
    </div>
    <div className="inline-block-element text-left">
      <span className="badge-container-count">{activations}</span>
      <span className="badge-container-label">licenças utilizadas</span>
      <span className="badge-container-small-label">de {licences} geradas</span>
    </div>
  </div>
)
export default LicencesActivationsBadgePresentation
