import React, { PureComponent } from "react"
import ReactHighcharts from "react-highcharts"

const baseChart = {
  chart: {
    type: "bar"
  },
  title: {
    text: null
  },
  colors: ["#05d3c8", "#e50b05"],
  yAxis: {
    min: 0,
    title: {
      text: null
    }
  },
  plotOptions: {
    bar: {
      stacking: "percent",
      dataLabels: {
        enabled: true,
        format: "{percentage:.0f}%",
        inside: true,
        x: -10,
        style: {
          textOutline: false
        }
      }
    }
  },
  tooltip: {
    headerFormat:
      "<span style=\"color:{series.color}\"><b>{point.percentage:.0f}%</b></span><br/>",
    pointFormat: "{point.y} questões"
  }
}

class DailyMissionPerformanceByAreaChartPresentation extends PureComponent {
  render () {
    const mergedChartConfig = Object.assign({}, baseChart, this.props.patch)
    return <ReactHighcharts config={mergedChartConfig} isPureConfig />
  }
}

export default DailyMissionPerformanceByAreaChartPresentation
