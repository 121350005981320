import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"

import { ChartFilter } from "home/components/filters"

class EnemExamFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      options: []
    }
  }

  shouldComponentUpdate (nextState) {
    return this.state.options != nextState.options
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("exams", { category: "ENEM", ...filters }, response => this.setOptionsFromResponse(response.data))
  }

  setOptionsFromResponse (data) {
    this.setState({
      options: this.parseExams(data)
    })
  }

  parseExams (data) {
    const options = data.map(x => {
      return {
        value: x.exam_id,
        label: x.exam_name
      }
    })

    const sortedOptions = options.sort((a, b) => {
      return a.value - b.value
    })

    sortedOptions.unshift({
      value: "ALL",
      label: "Simulado / Avaliação"
    })

    return sortedOptions
  }

  render () {
    return <ChartFilter name={this.props.filterKey} options={this.state.options} />
  }
}

EnemExamFilter.propTypes = {
  filterKey: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolClass: state.filters.schoolClass,
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(EnemExamFilter)
