import BnccStudentPerformanceTableContainer from "home/components/bncc_proficiency_analysis/containers/student_performance_table_container"
import BnccAnswerResultTableContainer from "home/components/bncc_proficiency_analysis/containers/answer_result_table_container"
import AbilityByAreaChartContainer from "home/components/bncc_proficiency_analysis/containers/ability_by_area_chart_container"
import ThematicUnitByAreaChartContainer from "home/components/bncc_proficiency_analysis/containers/thematic_unit_by_area_chart_container"

export {
  BnccStudentPerformanceTableContainer,
  BnccAnswerResultTableContainer,
  AbilityByAreaChartContainer,
  ThematicUnitByAreaChartContainer
}
