import {
  SingleEssayTableContainer
} from "home/components/single_essay/containers"

import {
  SingleEssayTablePresentation
} from "home/components/single_essay/presentations"

export {
  SingleEssayTableContainer,
  SingleEssayTablePresentation
}
