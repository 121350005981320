import {
  BnccStudentPerformanceTableContainer,
  BnccAnswerResultTableContainer,
  AbilityByAreaChartContainer,
  ThematicUnitByAreaChartContainer
} from "home/components/bncc_proficiency_analysis/containers"

import {
  BnccStudentPerformanceTablePresentation,
  BnccAnswerResultTablePresentation,
  AbilityByAreaChartPresentation,
  ThematicUnitByAreaChartPresentation
} from "home/components/bncc_proficiency_analysis/presentations"

export {
  BnccStudentPerformanceTableContainer,
  BnccAnswerResultTableContainer,
  BnccStudentPerformanceTablePresentation,
  BnccAnswerResultTablePresentation,
  AbilityByAreaChartContainer,
  AbilityByAreaChartPresentation,
  ThematicUnitByAreaChartContainer,
  ThematicUnitByAreaChartPresentation
}
