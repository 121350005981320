import React, { Component } from "react"

export default class Footer extends Component {
  render () {
    return (
      <footer>
        <div className="grid-container">
          <div className="grid grid-gutters-paddding grid-center footer-container">
            <div className="grid-cell">
              <span className="logo-footer" />
              <span className="copyright-footer">
                2014-2022 - Escola 360º© | Mind Lab© - Todos os direitos reservados
              </span>
            </div>
            <div className="grid-cell text-right footer-brands">
              <a href="http://mindlab.net/" target="_blank" rel="noopener noreferrer" className="logo-mindlabNet">
              
              </a>
              <a href="https://mindzup.com.br/" target="_blank" rel="noopener noreferrer" className="logo-mindzup">
              
              </a>
              <a href="https://mindlab.com.br/" target="_blank" rel="noopener noreferrer" className="logo-mindlab">
      
              </a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
