import { buildUrl } from "home/api_helper"
import API from "api"

export function requestResource (resource, filters, callback) {
  const url = buildUrl(resource, filters)
  requestUrl(url, callback)
}

export function requestUrl (url, callback) {
  API.get(url, response => callback(response))
}
