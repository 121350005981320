import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { PerformanceTablePresentation } from "home/components/profile"
import TableWrapper from "home/components/table_wrapper"

const RESOURCE = "profile/performance"

class PerformanceTableContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tableData: {
        data: null,
        columns: null
      }
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("profile/performance", filters, response => this.setTableConfigFromResponse(response))
  }

  setTableConfigFromResponse (response) {
    this.setState({
      tableData: response.data
    })
  }

  render () {
    return (
      <TableWrapper
        presenter={PerformanceTablePresentation}
        tableData={this.state.tableData}
        resource={RESOURCE}
        filters={this.props.filters}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolClass: state.filters.schoolClass,
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(PerformanceTableContainer)
