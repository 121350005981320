import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { DailyMissionAccumulatedChartPresentation } from "home/components/daily_mission"

class DailyMissionAccumulatedChartContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      chartPatch: null
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("daily_mission/accumulated", filters, response => this.setChartConfigFromResponse(response))
  }

  setChartConfigFromResponse (response) {
    this.setState({
      chartPatch: response.data.patch
    })
  }

  render () {
    return <DailyMissionAccumulatedChartPresentation patch={this.state.chartPatch} />
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolClass: state.filters.schoolClass,
      schoolId: state.filters.schoolId,
      dateFilterStartDate: state.filters.dateFilterStartDate,
      dateFilterEndDate: state.filters.dateFilterEndDate
    }
  }
}

export default connect(mapStateToProps)(DailyMissionAccumulatedChartContainer)
