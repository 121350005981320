function makeParamFromFilters (chartFilters, filter) {
  if (Object.prototype.hasOwnProperty.call(chartFilters, filter)) {
    if (chartFilters[filter] !== "ALL" && chartFilters[filter] !== null) {
      return filter + "=" + chartFilters[filter]
    }
  }
  return ""
}

export function buildUrl (endpoint, chartFilters, format = "") {
  const filterNames = [
    "schoolYear",
    "schoolClass",
    "userId",
    "schoolId",
    "areaSlug",
    "examId",
    "knowledgeArea",
    "category",
    "dateFilterStartDate",
    "dateFilterEndDate",
    "enemExams/sessions/examId",
    "enemExams/performanceByArea",
    "enemAnalysis/studentPerformance/examId",
    "enemAnalysis/answerResult/examId",
    "saebExams/performanceByStudent",
    "saebExams/performanceByArea",
    "saebAnalysis/studentPerformance/examId",
    "saebAnalysis/answerResult/examId",
    "bnccExams/sessions/examId",
    "bnccExams/performanceByArea",
    "bnccAnalysis/studentPerformance/examId",
    "bnccAnalysis/answerResult/examId",
    "bnccAnalysis/ability/knowledgeArea",
    "bnccAnalysis/thematicUnit/knowledgeArea"
  ]
  const filterPairs = filterNames.map(filter => makeParamFromFilters(chartFilters, filter))

  if (format !== "") {
    filterPairs.push("format=" + format)
  }

  const queryString = filterPairs.filter(param => param !== "").join("&")

  if (queryString !== "") {
    return endpoint + "?" + queryString
  } else {
    return endpoint
  }
}

export default class APIHelper {
  static buildURL (endpoint, filters) {
    const queryStringPairs = []

    Object.keys(filters).forEach(key => {
      if (filters[key] !== "ALL" && filters[key] !== null) {
        queryStringPairs.push(key + "=" + filters[key])
      }
    })

    const queryString = queryStringPairs.join("&")

    return `${endpoint}/?${queryString}`
  }
}
