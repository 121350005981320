import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { LicencesActivationsBadgePresentation } from "home/components/profile"

class LicencesActivationsBadgeContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      licences: 0,
      activations: 0
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters.schoolId !== this.props.filters.schoolId) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("profile/licences_activations", filters, response => this.setBadgeConfigFromResponse(response))
  }

  setBadgeConfigFromResponse (response) {
    this.setState({
      activations: response.data.activations,
      licences: response.data.licences
    })
  }

  render () {
    return <LicencesActivationsBadgePresentation activations={this.state.activations} licences={this.state.licences} />
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(LicencesActivationsBadgeContainer)
