import React from "react"
import ReactHighcharts from "react-highcharts"
import HC_more from "highcharts/highcharts-more"
HC_more(ReactHighcharts.Highcharts)


// HighchartsMore(ReactHighcharts.Highcharts)


// const baseChart = {
//   chart: {
//     type: "bar"
//   },
//   title: {
//     text: "Proficiência (%) em Habilidades"
//   },
//   yAxis: {
//     min: 0,
//     max: 100,
//     tickAmount: 6,
//     title: {
//       text: "Proficiência (%)"
//     }
//   },
//   legend: {
//     symbolHeight: 0,
//     symbolPadding: 0,
//     labelFormatter () {
//       return ""
//     },
//     symbolWidth: 10
//   },
//   plotOptions: {
//     bar: {
//       events: {
//         legendItemClick () {
//           const series = this.chart.series
//           series.forEach(function (serie) {
//             serie.hide()
//           })

//           const newDescriptors = []

//           series[this.index]["data"].forEach((data, index) => {
//             newDescriptors[index] = data["name"]
//           })

//           this.chart.xAxis[0].setCategories(newDescriptors)
//         }
//       },
//       dataLabels: {
//         format: "{point.y}%",
//         enabled: true,
//         style: {
//           textOutline: false
//         }
//       }
//     }
//   },
//   tooltip: {
//     useHTML: true,
//     headerFormat: "",
//     pointFormat:
//       "<div style=\"width: 200px; white-space:normal;text-align: center;\">" +
//       "<span style=\"color:#00c89d\">{point.correct_answers} acertos </span> " +
//       "e <span style=\"color:#e50b05\">{point.wrong_answers} erros </span>" +
//       "<br>nessa habilidade" +
//       "<br><br><b>Descrição:</b>" +
//       "<br>{point.description}</div>"
//   }
// }

const baseChart = {
  chart: {
    polar: true,
    type: "line"
},

  title: {
      text: "Média vs Expectativa",
      align: "center",
      x: 0
  },

  pane: {
    size: "60%"
  },

  xAxis: {
      categories: ["Autorregulação", "Resolução de Problemas", "Trabalho em Equipe",
       "Tomada de Decisão", "Gerenciamento de Recursos", `Auto
       conhecimento`],
      tickmarkPlacement: "on",
      lineWidth: 0
  },

  yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
      min: 0
  },

  tooltip: {
      shared: true,
      pointFormat: "<span style='color:{series.color}'>{series.name}: <b>{point.y:,.0f}</b><br/>"
  },

  legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical"
  },

  series: [{
      name: "Média das Avalições",
      data: [9, 2, 7, 5, 6, 8],
      pointPlacement: "on"
  }, {
      name: "Avaliação Esperada",
      data: [7, 9, 6, 8, 5, 10],
      pointPlacement: "on"
  }],

  responsive: {
      rules: [{
          condition: {
              maxWidth: 700
          },
          chartOptions: {
              legend: {
                  align: "center",
                  verticalAlign: "bottom",
                  layout: "horizontal"
              },
              pane: {
                  size: "70%"
              }
          }
      }]
  }
}


class AbilityByAreaChartPresentation extends React.PureComponent {
  render () {
    // const mergedChartConfig = Object.assign({}, baseChart, this.props.patch)
    return <ReactHighcharts config={baseChart} />
  }
}

export default AbilityByAreaChartPresentation
