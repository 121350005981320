import React, { PureComponent } from "react"

class DataTableRowsText extends PureComponent {
  render () {
    return (
      <div className="table-text-container">
        <span className="question-mark-icon" />
        <span className="table-footer-limit-text">
          Estamos apresentando apenas as primeiras 200 linhas desta tabela.{" "}
          <strong>
            {" "}
            Para visualizar todas as linhas, basta BAIXAR A TABELA COMPLETA e
            abrir o arquivo no Excel{" "}
          </strong>{" "}
          :)
        </span>
      </div>
    )
  }
}

export default DataTableRowsText
