import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { FinalizedExamsChartPresentation } from "home/components/profile"

class FinalizedExamsChartContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      chartPatch: null
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters.schoolId !== this.props.filters.schoolId) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource("profile/finalized_exams", filters, response => this.setChartConfigFromResponse(response))
  }

  setChartConfigFromResponse (response) {
    this.setState({
      chartPatch: response.data.patch
    })
  }

  render () {
    if (this.state.chartPatch === null) {
      return <div>Aguardando dados</div>
    } else {
      return <FinalizedExamsChartPresentation patch={this.state.chartPatch} />
    }
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      schoolId: state.filters.schoolId
    }
  }
}

export default connect(mapStateToProps)(FinalizedExamsChartContainer)
