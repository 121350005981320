import React, { Component } from "react"
import { connect } from "react-redux"
import { requestResource } from "home/api_request"
import { SaebAnswerResultTablePresentation } from "home/components/saeb_proficiency_analysis"
import TableWrapper from "home/components/table_wrapper"

const RESOURCE = "saeb_proficiency_analysis/answer_result"

class SaebAnswerResultTableContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tableData: {
        data: null,
        columns: null
      }
    }
  }

  componentDidMount () {
    this.updateData()
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.filters) != JSON.stringify(this.props.filters)) {
      this.updateData()
    }
  }

  updateData () {
    const { filters } = this.props

    requestResource(RESOURCE, filters, response => this.setChartConfigFromResponse(response))
  }

  setChartConfigFromResponse (response) {
    this.setState({
      tableData: response.data
    })
  }

  render () {
    return (
      <TableWrapper
        presenter={SaebAnswerResultTablePresentation}
        tableData={this.state.tableData}
        resource={RESOURCE}
        filters={this.props.filters}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: {
      userId: state.filters.userId,
      schoolYear: state.filters.schoolYear,
      schoolClass: state.filters.schoolClass,
      schoolId: state.filters.schoolId,
      "saebAnalysis/answerResult/examId": state.filters["saebAnalysis/answerResult/examId"]
    }
  }
}

export default connect(mapStateToProps)(SaebAnswerResultTableContainer)
