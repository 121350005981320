import React, { Component } from "react"
import PropTypes from "prop-types"
import { Table, Column, Cell } from "fixed-data-table-2"
import { TextCell, DataTableRowsText, DataTableFooter, LinkCell } from "home/components/data_table"

const COLUMN_WIDTH = 220

export default class DataTable extends Component {
  constructor (props) {
    super(props)

    const columnWidths = []
    this.props.tableData.columns.forEach(function (entry) {
      columnWidths[entry.key] = COLUMN_WIDTH
    })
    this.state = {
      columnWidths
    }

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this)
    this.setTableSize = this.setTableSize.bind(this)
  }

  renderRows (data) {
    //https://github.com/facebook/fixed-data-table/issues/234
    const { columnWidths } = this.state
    const tableColumns = data.columns.map((row, index) => (
      <Column
        key={index}
        fixed={row.key == "user_name"}
        columnKey={row.key}
        header={<Cell>{row.label}</Cell>}
        cell={row.key.startsWith("link_") ? <LinkCell data={data.data}/>: <TextCell data={data.data} />}
        isResizable
        width={columnWidths[row.key]}
      />
    ))

    return [tableColumns]
  }

  _onColumnResizeEndCallback (newColumnWidth, columnKey) {
    const previousState = this.state.columnWidths
    previousState[columnKey] = newColumnWidth
    this.setState({
      columnWidths: previousState
    })
  }

  setTableSize (value) {
    if (value == "big") {
      return 1222
    }
    if (value == "small") {
      return 599
    }
  }

  render () {
    return (
      <div className="smart-table-container">
        <div className="smart-table-container-scroll">
          <Table
            rowsCount={this.props.tableData.data.length}
            rowHeight={22}
            isColumnResizing={false}
            headerHeight={25}
            width={this.setTableSize(this.props.tableSize)}
            height={230}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            {...this.props}
          >
            {this.renderRows(this.props.tableData)}
          </Table>
        </div>
        <DataTableFooter
          rowsCount={this.props.tableData.rows_count}
          resource={this.props.resource}
          hasFilters={this.props.hasFilters}
        />
        <DataTableRowsText />
      </div>
    )
  }
}

DataTable.propTypes = {
  tableData: PropTypes.object
}
