import React, { Component } from "react"
import { connect } from "react-redux"
import ProductsListPresentation from "home/components/products_list/products_list_presentation"

class ProductsListContainer extends Component {
  render () {
    if (this.props.products.length < 1) {
      return <div>Aguardando dados</div>
    } else {
      return <ProductsListPresentation products={this.props.products} />
    }
  }
}

const mapStateToProps = state => {
  return {
    products: state.products
  }
}

export default connect(mapStateToProps)(ProductsListContainer)
