import React, { Component } from "react"
import {
  ProductBoxAvailable,
  ProductBoxUnavailable,
  ProductBoxNotImplemented
} from "home/components/layout/product_boxes"

const AVAILABLE = 1,
  UNAVAILABLE = 2,
  NOT_IMPLEMENTED = 3

class ProductBox extends Component {
  static get AVAILABLE () {
    return AVAILABLE
  }

  static get UNAVAILABLE () {
    return UNAVAILABLE
  }

  static get NOT_IMPLEMENTED () {
    return NOT_IMPLEMENTED
  }

  render () {
    switch (this.props.kind) {
      case AVAILABLE:
        return (
          <ProductBoxAvailable
            border_color={this.props.border_color}
            to={this.props.to}
            icon={this.props.icon}
            caption={this.props.caption}
          />
        )
      case UNAVAILABLE:
        return (
          <ProductBoxUnavailable
            icon={this.props.icon}
            caption={this.props.caption}
          />
        )
      case NOT_IMPLEMENTED:
        return <ProductBoxNotImplemented caption={this.props.caption} />
    }
  }
}

export default ProductBox
