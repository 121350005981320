import React from "react"
import { connect } from "react-redux"
import { Filter } from "home/components/filters"
import getCurrentSchool from "home/current-school"

const SchoolClassFilter = ({ schoolClasses }) => {
  const options = [{ value: "ALL", label: "Todas" }].concat(
    schoolClasses.map(schoolClass => {
      return {
        value: schoolClass,
        label: schoolClass
      }
    })
  )

  return <Filter caption="Turma" name="schoolClass" options={options} />
}

const mapStateToProps = state => {
  const currentSchool = getCurrentSchool(state.auth.currentUser, state.filters.schoolId)
  const schoolClasses = currentSchool ? currentSchool.school_classes : []

  return { schoolClasses }
}

export default connect(mapStateToProps)(SchoolClassFilter)
