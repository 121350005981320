import React from "react"
import Product from "home/components/product"

import { CaptionedDiv, CaptionedDivWithFilter } from "home/components/layout"
import { EnemExamSessionsTableContainer, EnemExamPerformanceByAreaContainer } from "home/components/enem_exam"
import { SingleEssayTableContainer } from "home/components/single_essay"

import { EnemExamFilter } from "home/components/filters"
import { MixpanelTrack } from "home/mixpanel"

const EnemExamProduct = () => {
  MixpanelTrack("Acessou Produto Avaliações ENEM")

  return (
    <Product caption="Avaliações ENEM">
      <div className="product-element">
        <div className="grid">
          <div className="grid-cell">
            <CaptionedDivWithFilter
              filterType={EnemExamFilter}
              filterCategory="ENEM"
              filterKey="enemExams/performanceByArea"
              caption="Desempenho por Área do Conhecimento"
            >
              <EnemExamPerformanceByAreaContainer />
            </CaptionedDivWithFilter>
            <div className="product-element-footer" />
          </div>
        </div>
        <div className="product-element">
          <CaptionedDivWithFilter
            filterType={EnemExamFilter}
            filterCategory="ENEM"
            filterKey="enemExams/sessions/examId"
            caption="Dados e desempenho por aluno"
          >
            <EnemExamSessionsTableContainer />
          </CaptionedDivWithFilter>
        </div>
        <div className="product-element">
          <CaptionedDiv caption="Redações">
            <SingleEssayTableContainer />
          </CaptionedDiv>
        </div>
      </div>
    </Product>
  )
}

export default EnemExamProduct
