import React, { Component } from "react"
import PropTypes from "prop-types"

export default class CaptionedDivWithFilter extends Component {
  render () {
    const DynamicFilter = this.props.filterType

    return (
      <div className="product-element">
        <div className="product-element-title with-filter">
          <h2>{this.props.caption}</h2>
          <DynamicFilter filterKey={this.props.filterKey} filterCategory={this.props.filterCategory} />
        </div>
        {React.Children.toArray(this.props.children).map((children, index) => (
          <div key={index}>{children}</div>
        ))}
      </div>
    )
  }
}

CaptionedDivWithFilter.propTypes = {
  caption: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  filterCategory: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired])
}
