import React, { Component } from "react"
import ReactHighcharts from "react-highcharts"

const baseChart = {
  chart: {
    type: "bar",
    marginRight: 50
  },
  title: {
    text: null
  },
  tooltip: {
    headerFormat: "<span style='font-weight: bold;'>{point.key}</span><table>",
    pointFormat: "<tr><td style='padding:0'>{point.percentage}%</td><td style='padding:0'></td>",
    footerFormat: "</table>",
    useHTML: true
  },
  plotOptions: {
    bar: {
      grouping: false,
      shadow: false,
      borderWidth: 1,
      dataLabels: {
        enabled: false
      }
    }
  },
  xAxis: {
    type: "category"
  },
  yAxis: {
    title: {
      text: null
    }
  }
}

class AbilityChartPresentation extends Component {
  render () {
    const mergedChartConfig = Object.assign({}, baseChart, this.props.patch)

    return (
      <div>
        <ReactHighcharts config={mergedChartConfig} />
        {/* <div className="chart-container-small-label">*Alunos que ativaram o código.</div> */}
      </div>
    )
  }
}

export default AbilityChartPresentation
