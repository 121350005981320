import EnemStudentPerformanceTablePresentation from "home/components/enem_proficiency_analysis/presentations/student_performance_table_presentation"
import EnemAnswerResultTablePresentation from "home/components/enem_proficiency_analysis/presentations/answer_result_table_presentation"
import CompetenceByAreaChartPresentation from "home/components/enem_proficiency_analysis/presentations/competence_by_area_chart_presentation"
import AbilityByAreaChartPresentation from "home/components/enem_proficiency_analysis/presentations/ability_by_area_chart_presentation"

export {
  EnemStudentPerformanceTablePresentation,
  EnemAnswerResultTablePresentation,
  CompetenceByAreaChartPresentation,
  AbilityByAreaChartPresentation
}
