import { pageWhiteList } from "../../../../pages"

const ProductsListInfo = [
  {
    id: "profile",
    label: "Perfil",
    url: "/perfil",
  },
  {
    id: "daily-mission",
    label: "Missão Diária",
    url: "/missao-diaria",
  },
  {
    id: "enem-exams",
    label: "Avaliações ENEM",
    url: "/avaliacoes-enem",
  },
  {
    id: "saeb-exams",
    label: "Avaliações SAEB",
    url: "/avaliacoes-saeb",
  },
  {
    id: "bncc-exams",
    label: "Avaliações BNCC",
    url: "/avaliacoes-bncc",
  },
  {
    id: "bncc-exams",
    label: "Avaliações BNCC",
    url: "/avaliacoes-bncc",
  },
  {
    id: "enem-proficiency-analysis",
    label: "Análises de Proficiência (ENEM)",
    url: "/analises-de-proficiencia-enem",
  },
  {
    id: "saeb-proficiency-analysis",
    label: "Análises de Proficiência (SAEB)",
    url: "/analises-de-proficiencia-saeb",
  },
  {
    id: "bncc-proficiency-analysis",
    label: "Análises de Proficiência (BNCC)",
    url: "/analises-de-proficiencia-bncc",
  },
  {
    id: "socioemotional",
    label: "Avaliação Socioemocional",
    url: "/avaliacoes-socioemocional",
  },
]

export default ProductsListInfo.filter((product) => pageWhiteList.includes(product.url))
