import React from "react"
import { connect } from "react-redux"
import MobileMenuOption from "home/components/layout/mobile/mobile_menu_option"
import MenuMobileLogout from "home/components/layout/mobile/mobile_menu_logout"
import ProductsListInfo from "home/components/layout/mobile/mobile_menu_helper"
import { setMobileMenuVisibility } from "home/actions"
import getCurrentSchool from "home/current-school"

const MobileMenu = ({ products, schoolName, isMobileMenuVisible, dispatch }) => {
  if (isMobileMenuVisible) {
    return (
      <div className="mobile-menu-container" id="mobile-menu">
        <div className="mobile-menu-header grid grid-justify-between grid-center">
          <span className="mobile-menu-title">Menu</span>
          <span className="mobile-menu-close" onClick={() => dispatch(setMobileMenuVisibility(false))}>
            X
          </span>
        </div>
        <div className="mobile-menu-school-info">
          <span className="school-profile-badge-icon mobile-menu-icon" />
          <span className="mobile-menu-school-name">{schoolName}</span>
        </div>
        <ul>
          <MobileMenuOption label="Home" url="/" />
          {products.map((product) => {
            const foundProduct = ProductsListInfo.find((p) => p.id === product)
            return (
              foundProduct && (
                <MobileMenuOption key={foundProduct.id} label={foundProduct.label} url={foundProduct.url} />
              )
            )
          })}
          <li>
            <MenuMobileLogout />
          </li>
        </ul>
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
  const currentSchool = getCurrentSchool(state.auth.currentUser, state.filters.schoolId)
  const schoolName = currentSchool ? currentSchool.name : ""

  return {
    schoolName: schoolName,
    products: state.products,
    isMobileMenuVisible: state.isMobileMenuVisible,
  }
}

export default connect(mapStateToProps)(MobileMenu)
