import React, { PureComponent } from "react"
import { DataTable } from "home/components/data_table"

export default class DailyMissionEngagementAndPerformanceTablePresentation extends PureComponent {
  render () {
    return (
      <DataTable
        tableData={this.props.tableData}
        resource={this.props.resource}
        hasFilters={this.props.hasFilters}
        tableSize="big"
        height={700}
      />
    )
  }
}
