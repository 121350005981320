import { initialState, STORAGE_KEY } from "home/store"
import { REHYDRATE } from "redux-persist"
import { MixpanelTrackFilter } from "home/mixpanel"
import { removeAuthToken } from "auth"

export const SET_FILTER = "filters/SET"
export const SET_PRODUCTS = "products/SET"
export const SET_MOBILE_MENU_VISIBILITY = "SET_MOBILE_MENU_VISIBILITY"
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER"
export const RESET_KEY = "RESET"

export function setFilter (filter) {
  const filterNames = Object.keys(filter)
  MixpanelTrackFilter(filterNames)

  return dispatch => {
    filterNames.forEach(name =>
      dispatch({
        type: SET_FILTER,
        filter: { name: name, value: filter[name] }
      })
    )
  }
}

export function setProducts (products) {
  return { type: SET_PRODUCTS, products }
}

export function updateCurrentUser (user) {
  return { type: UPDATE_CURRENT_USER, user }
}

export function logout () {
  return dispatch => {
    removeAuthToken()

    const reducer = initialState()
    dispatch({ type: RESET_KEY })
    dispatch({ type: REHYDRATE, key: STORAGE_KEY, payload: reducer })
  }
}

export function setMobileMenuVisibility (isMobileMenuVisible) {
  return { type: SET_MOBILE_MENU_VISIBILITY, isMobileMenuVisible }
}
