import React from "react"
import Product from "home/components/product"
import { CaptionedDiv } from "home/components/layout"
import {
  DailyMissionEngagementAndPerformanceTableContainer,
  DailyMissionPerformanceByAreaChartContainer,
  DailyMissionTotalPeriodChartContainer,
  DailyMissionAccumulatedChartContainer
} from "home/components/daily_mission"
import { MixpanelTrack } from "home/mixpanel"

export default class DailyMissionProduct extends React.Component {
  componentDidMount () {
    MixpanelTrack("Acessou Produto Missão Diária")
  }

  render () {
    return (
      <Product caption="Missão diária" location={this.props.location.pathname}>
        <div className="grid grid-double">
          <div className="grid-cell">
            <CaptionedDiv caption="Questões Respondidas | Total por período">
              <DailyMissionTotalPeriodChartContainer />
            </CaptionedDiv>
            <div className="product-element-footer" />
          </div>
          <div className="grid-cell">
            <CaptionedDiv caption="Questões Respondidas | Total acumulado">
              <DailyMissionAccumulatedChartContainer />
            </CaptionedDiv>
            <div className="product-element-footer" />
          </div>
        </div>
        <div className="grid">
          <div className="grid-cell">
            <CaptionedDiv caption="Desempenho por Área do Conhecimento (porcentagem de acerto)">
              <DailyMissionPerformanceByAreaChartContainer />
            </CaptionedDiv>
            <div className="product-element-footer" />
          </div>
        </div>

        <div className="grid">
          <div className="grid-cell">
            <CaptionedDiv caption="Dados e Desempenho por aluno">
              <DailyMissionEngagementAndPerformanceTableContainer />
            </CaptionedDiv>
          </div>
        </div>
      </Product>
    )
  }
}
