import React from "react"
import { connect } from "react-redux"
import { Filter } from "home/components/filters"
import getCurrentSchool from "home/current-school"

const SchoolYearFilter = ({ schoolYears }) => {
  const options = [{ value: "ALL", label: "Todas" }].concat(
    schoolYears.map(schoolYear => {
      return {
        value: schoolYear.year,
        label: schoolYear.year_formatted
      }
    })
  )

  return <Filter caption="Ano" name="schoolYear" options={options} />
}

const mapStateToProps = state => {
  const currentSchool = getCurrentSchool(state.auth.currentUser, state.filters.schoolId)
  const schoolYears = currentSchool ? currentSchool.school_years : []

  return { schoolYears }
}

export default connect(mapStateToProps)(SchoolYearFilter)
