import React, { PureComponent } from "react"

class ProductBoxUnavailable extends PureComponent {
  render () {
    return (
      <div className="product-box unavailable">
        <span
          className={"product-box-icon " + this.props.icon + "-unavailable"}
        />
        <span className="product-box-label unavailable">
          {this.props.caption}
        </span>
        <span className="product-box-unavailable-text">
          Este produto não faz parte do seu pacote. Para adquirí-lo, entre em
          contato conosco.
        </span>
        <span className={"product-box-link unavailable"}>
          <span className="product-box-link-icon" />
        </span>
      </div>
    )
  }
}

export default ProductBoxUnavailable
