import React from "react"
import ReactHighcharts from "react-highcharts"

const baseChart = {
  chart: {
    type: "column"
  },
  title: {
    text: null
  },
  xAxis: {
    type: "category"
  },

  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0
    }
  },
  tooltip: {
    headerFormat: "<span style='font-weight: bold;'>{point.key}</span><table>",
    pointFormat:
      "<tr><td style='color:{series.color};padding:0;text-align: right;'>{series.name}: &nbsp; </td>" +
      "<td style='padding:0'>{point.y}% de acerto ({point.total_correct} acertos/{point.total_wrong} erros)</td></tr>",
    footerFormat: "</table>",
    shared: true,
    useHTML: true
  },
  yAxis: {
    title: {
      text: "Porcentagem de acerto"
    },
    labels: {
      format: "{value}%"
    }
  }
}

const BnccExamPerformanceByAreaChartPresentation = (props) => {
  const mergedChartConfig = Object.assign({}, baseChart, props.patch)
  return <ReactHighcharts config={mergedChartConfig} isPureConfig />
}

export default BnccExamPerformanceByAreaChartPresentation
